import storeExtensions from "@/plugins/store-extensions";

export const userSettingsModule = {
  namespaced: true,
  state: {
    axios: storeExtensions.createAxios(), // Needed for different baseUrl
    selectedFields: []
  },
  getters: {
    selectedFields: state => state.selectedFields
  },
  mutations: {
    updateSelectedFields(state, selectedFields) {
      state.selectedFields = selectedFields;
    }
  },
  actions: {
    ...storeExtensions.actions,
    getAllUserSelectedFields: (context, payload) => {
      return context.dispatch("getApiData", {
        url: `api/userSettings/selected-fields/user/${payload}`,
        mutation: "updateSelectedFields",
        label: `Update Selected Fields`
      });
    },
    saveDataTypeSelectedFields: (context, payload) => {
      return context.dispatch("postApiData", {
        url: `api/userSettings/selected-fields`,
        payload: {
          dataTypeId: payload.dataTypeId,
          fields: payload.fields,
          userId: payload.userId
        },
        label: `Update Selected Fields for a Data Type`
      });
    }
  }
};
