<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table :headers="headers" :items="users" :search="search">
      <template v-slot:[`item.enableDisableUser`]="{ item }">
        <v-switch
          v-model="item.active"
          inset
          @click="
            $emit(`${item.active ? 'enable-user' : 'disable-user'}`, item)
          "
        ></v-switch>
      </template>
      <template v-slot:[`item.resetPassword`]="{ item }">
        <v-icon small @click="$emit('password-reset-request', item)"
          >fas fa-key</v-icon
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  data: () => ({
    search: "",
    headers: [
      { text: "Name", align: "start", value: "name" },
      { text: "Email Address", align: "start", value: "emailAddress" },
      { text: "Created By", value: "createdBy" },
      { text: "Created Date", value: "createdDate" },
      { text: "Modified By", value: "modifiedBy" },
      { text: "Modified Date", value: "modifiedDate" },
      { text: "Active", value: "enableDisableUser", sortable: false },
      {
        text: "Reset Password",
        value: "resetPassword",
        sortable: false
      }
    ]
  }),
  computed: {
    users() {
      return this.$store.getters[`userModule/users`];
    }
  },
  methods: {}
};
</script>

<style lang="scss">
#no-background-hover::before {
  background-color: transparent !important;
}
</style>
