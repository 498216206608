<template>
  <v-container fluid>
    <v-overlay opacity="0.50" :value="updateLoading" style="z-index:20001;">
      <v-progress-circular
        indeterminate
        color="#2A3F54"
        size="75"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-container class="text-xs-center">
        <v-card>
          <v-card-title>Login</v-card-title>
          <v-form ref="form" v-model="isFormValid">
            <v-card-text>
              <v-text-field
                :rules="emailRules"
                v-model="userCredentials.emailAddress"
                label="Email Address"
                required
              ></v-text-field>
              <v-text-field
                v-model="userCredentials.password"
                type="password"
                label="Password"
                required
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn link href="credits.txt" target="_blank">
                <v-icon fab small class="mr-2">fas fa-info-circle</v-icon>
                Open Source Credits
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                :disabled="!isFormValid"
                @click="loginUser"
                type="submit"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Login",
  data: () => ({
    isFormValid: false,
    dialog: true,
    updateLoading: false,
    emailRules: [v => /.+@.+/.test(v) || "Invalid Email address"],
    userCredentials: {
      emailAddress: "",
      password: ""
    }
  }),
  computed: {},
  methods: {
    async loginUser() {
      this.updateLoading = true;
      this.dialog = true;

      await this.$store
        .dispatch("userModule/loginUser", this.userCredentials)
        .then(
          success => {
            this.updateLoading = false;
            var userData = success;
            this.$store.commit("userModule/updateUser", userData);
            localStorage.setItem(
              `${location.hostname}.user`,
              JSON.stringify({
                guid: userData.guid
              })
            );
            var nextLocation = this.$route.params.nextUrl
              ? `${this.$route.params.nextUrl}`
              : "/";
            window.location.href = nextLocation;
          },
          error => {
            this.updateLoading = false;
            var errorMessage = "";
            if (error.response.status === 401) {
              errorMessage = `Invalid Username or Password or your reset link is expired.<br/>If this issues continues please reach out to your WD contact.`;
            } else {
              errorMessage =
                error.response.data.exceptionMessage ||
                "Error. Please try again";
            }
            this.$framework.showDialog("Error", errorMessage, "error");
          }
        )
        .catch(error => {
          this.updateLoading = false;
          this.$framework.showDialog("Error", error, "error");
        });
    }
  },
  watch: {}
};
</script>

<style lang="scss"></style>
