<template>
  <div ref="parentContainer">
    <div
      :style="{
        height:
          stickied && !expanded
            ? '70px'
            : smallScreenSize
            ? smallViewportHeight
            : initialHeight,
        width: toolbarWidth
      }"
      ref="moduleFilters"
      :class="{
        sticky: stickied,
        'module-filters': true
      }"
    >
      <slot name="filterItems"></slot>
      <div class="filter-chevron" v-if="stickied && !disableExpansion">
        <v-btn icon>
          <v-icon @click="onClick"
            >fa
            {{
              expanded ? "fa-chevron-circle-up" : "fa-chevron-circle-down"
            }}</v-icon
          >
        </v-btn>
      </div>
    </div>
    <div class="sticky-spacer"></div>
  </div>
</template>

<script>
export default {
  props: {
    initialHeight: {
      type: String,
      required: false,
      default: "140px"
    },
    smallViewportHeight: {
      type: String,
      required: false,
      default: "450px"
    },
    disableExpansion: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    stickied: false,
    expanded: false,
    toolbarWidth: "100%"
  }),
  computed: {
    smallScreenSize() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    }
  },
  methods: {
    onClick() {
      this.expanded = !this.expanded;
      this.$emit("filter-expanded", this.expanded);
    },
    onScroll() {
      if (this.smallScreenSize) {
        this.stickied = false;
        this.expanded = true;
      } else {
        if (window.pageYOffset >= 64) {
          this.expanded = !this.stickied ? false : this.expanded;
          this.stickied = true;
        } else {
          this.stickied = false;
          this.expanded = false;
        }
      }
      this.$emit("filter-stickied", this.stickied);
      this.$emit("filter-expanded", this.expanded);
    },
    setToolbarWidth() {
      if (this.$refs?.parentContainer?.clientWidth) {
        this.toolbarWidth = `${this.$refs.parentContainer?.clientWidth}px`;
      }
    },
    onResize() {
      if (this.smallScreenSize) {
        this.stickied = false;
        this.expanded = true;
      }
      this.setToolbarWidth();
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setToolbarWidth();
      window.addEventListener("resize", this.onResize);
      window.addEventListener("scroll", this.onScroll);
    });
  }
};
</script>

<style lang="scss">
.module-filters {
  display: flex;
  position: relative;
  z-index: 0;
  padding: 4px 16px;
  box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%),
    0 1px 10px 0 rgb(0 0 0 / 12%);
}
.theme--light .module-filters {
  background-color: #f5f5f5;
}
.theme--dark .module-filters {
  background-color: #272727;
}
.module-filters.sticky {
  position: fixed;
  top: 64px;
  z-index: 5;
  border-top: 1px solid black;
}
.module-filters.sticky + .sticky-spacer {
  margin-top: 134px;
}
.filter-items-content {
  width: 100%;
}
.filter-chevron {
  display: flex;
  align-items: center;
}
</style>
