<template>
  <v-card>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <template>
        <v-dialog v-model="dialogNotes" max-width="500px">
          <v-card>
            <v-card-title class="headline">Notes</v-card-title>
            <v-card-text>{{ selectedItemNotes }}</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogNotes = false"
                >Cancel</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </template>
      <v-data-table
        height="600"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :headers="headers"
        :items="rowData"
        :search="search"
      >
        <template v-slot:[`item.notes`]="{ item }">
          <v-tooltip bottom>
            <template v-slot:[`activator`]="{ on }">
              <v-icon
                small
                class="mr-2"
                v-on="on"
                @click="showNotesDialog(item)"
                >far fa-file-alt</v-icon
              >
            </template>
            <span>Notes: {{ item.notes }}</span>
          </v-tooltip>
        </template>
        <template v-slot:[`item.details`]="{ item }">
          <v-btn icon id="no-background-hover" @click="$emit('view-data', item)"
            ><v-icon small class="mr-2">fas fa-table</v-icon></v-btn
          >
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="$emit('reupload-data', item)"
            >fas fa-sync</v-icon
          >
          <v-icon small class="mr-2" @click="$emit('download-data', item)"
            >fas fa-download</v-icon
          >
          <v-icon small class="mr-2" @click="$emit('retire-data', item)"
            >fas fa-trash-alt</v-icon
          >
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: ["format", "dataType"],
  data: () => ({
    sortBy: "dataDateFormatted",
    sortDesc: true,
    search: "",
    headers: [
      { text: "File Date", align: "start", value: "dataDateFormatted" },
      { text: "File Name", value: "name" },
      { text: "Data Type", value: "dataType" },
      { text: "Format", value: "format" },
      { text: "Upload Date", value: "createdDate" },
      { text: "Uploaded By", value: "createdBy" },
      { text: "Notes", value: "notes", sortable: false },
      { text: "Details", value: "details", sortable: false },
      { text: "Actions", value: "actions", sortable: false }
    ],
    selectedItemNotes: "",
    dialogNotes: false
  }),
  computed: {
    rowData() {
      var module =
        this.dataType == "DPPM Data"
          ? "dppmModule"
          : this.dataType == "SI Line Integration Data"
          ? "lineIntegrationModule"
          : "exSmartModule";
      var rows = this.$store.getters[`${module}/previewFiles`].filter(
        x => x.format == this.format
      );
      rows = rows.map(row => ({
        ...row,
        dataDateFormatted: new Date(row.dataDate).toISOString().substr(0, 7)
      }));
      return rows;
    }
  },
  methods: {
    showNotesDialog(item) {
      this.selectedItemNotes = item.notes;
      this.dialogNotes = true;
    }
  }
};
</script>

<style lang="scss">
#no-background-hover::before {
  background-color: transparent !important;
}

.theme--light.v-data-table th,
.theme--light.v-data-table td {
  color: rgba(0, 0, 0, 0.54) !important;
}

.theme--dark.v-data-table th,
.theme--dark.v-data-table td {
  color: white !important;
}
</style>
