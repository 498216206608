import axios from "axios";

const localStorageKey = `${location.hostname}.user`;
const user = JSON.parse(localStorage.getItem(localStorageKey) || "{}");
export default {
  state: {
    axios: axios.create({
      baseURL: "",
      headers: { GUID: user.guid }
    }),
    user
  },

  mutations: {
    configApi(state, params) {
      if (typeof params.baseUrl != "undefined") {
        state.axios.defaults.baseURL = params.baseUrl;
      }
    }
  },

  actions: {
    /* ------------------------------------------------------------------------
      getApiData - Generic api get service
      params = {
        url: api endpoint
        mutation: optional mutation callback name
        label: optional friendly name of data set for error message
        map: optional response.data mapper (e.g. (response) => response.data.name )
      }
    ------------------------------------------------------------------------ */
    getApiData: (context, params) =>
      new Promise((resolve, reject) => {
        (context.state.axios || context.rootState.axios)
          .get(params.url)
          .then(response => {
            if (response.status === 200) {
              let map = params.map || (response => response.data);
              if (params.mutation) {
                context.commit(params.mutation, map(response));
              }
              resolve(map(response));
            } else {
              reject(`Error getting '${params.label || params.url}'.`);
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              reject(
                `Session Expired getting '${params.label ||
                  params.url}', please login again.`
              );
            } else {
              reject(
                error.response.data.exceptionMessage ||
                  `Error getting '${params.label || params.url}'.`
              );
            }
          });
      }),
    /* ------------------------------------------------------------------------
      putApiData - Generic api put service
      params = {
        url: api endpoint
        payload: request payload
        mutation: optional mutation callback name
        label: optional friendly name of data set for error message
      }
    ------------------------------------------------------------------------ */
    putApiData: (context, params) =>
      new Promise((resolve, reject) => {
        (context.state.axios || context.rootState.axios)
          .put(params.url, params.payload)
          .then(response => {
            if (params.mutation) {
              context.commit(params.mutation, response.data);
            }
            resolve(response.data);
          })
          .catch(error => {
            if (error.response.status === 401) {
              reject(
                `Session Expired posting '${params.label ||
                  params.url}', please login again.`
              );
            } else {
              reject(
                error.response.data.exceptionMessage ||
                  `Error putting '${params.label || params.url}'.`
              );
            }
          });
      }),
    /* ------------------------------------------------------------------------
      postApiData - Generic api post service
      params = {
        url: api endpoint
        payload: request payload
        mutation: optional mutation callback name
        label: optional friendly name of data set for error message
      }
    ------------------------------------------------------------------------ */
    postApiData: (context, params) =>
      new Promise((resolve, reject) => {
        (context.state.axios || context.rootState.axios)
          .post(params.url, params.payload, params.headers)
          .then(response => {
            if (params.mutation) {
              context.commit(params.mutation, response.data);
            }
            resolve(response.data);
          })
          .catch(error => {
            if (error.response.status === 401) {
              reject(
                `Session Expired posting '${params.label ||
                  params.url}', please login again.`
              );
            } else {
              reject(
                error.response.data.exceptionMessage ||
                  `Error posting '${params.label || params.url}'.`
              );
            }
          });
      }),
    /* ------------------------------------------------------------------------
      deleteApiData - Generic api delete service
      params = {
        url: api endpoint
        label: optional friendly name of data set for error message
      }
    ------------------------------------------------------------------------ */
    deleteApiData: (context, params) =>
      new Promise((resolve, reject) => {
        (context.state.axios || context.rootState.axios)
          .delete(params.url)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            if (error.response.status === 401) {
              reject(
                `Session Expired posting '${params.label ||
                  params.url}', please login again.`
              );
            } else {
              reject(
                error.response.data.exceptionMessage ||
                  `Error deleting '${params.label || params.url}'.`
              );
            }
          });
      })
  },

  /* --------------------------------------------------------------------------
    Helper methods
  -------------------------------------------------------------------------- */
  createAxios: () =>
    axios.create({
      baseURL: "",
      //headers: { Authorization: "Bearer " + oidcUser.access_token }
      headers: { GUID: user.guid }
    })
};
