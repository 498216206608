export const ChartConfiguration = {
  ElectronicsErrorCount: {
    name: "ElectronicsErrorCount",
    title: {
      text: "Electronics Error Count Distributions"
    }
  },
  ErrorCount: {
    name: "ErrorCount",
    title: {
      text: "Recovered Error Count Distributions"
    },
    axes: [
      {
        type: "log",
        tick: {
          count: 4
        },
        min: 0.001
      },
      {
        type: "log",
        tick: {
          count: 3
        },
        min: 0.0001
      }
    ]
  },
  ErrorCountNormalized: {
    name: "ErrorCountNormalized",
    title: {
      text: "Recovered Error Count Distributions [Normalized]"
    },
    axes: [
      {
        type: "log",
        tick: {
          count: 4
        },
        title: {
          text: "Error Rates [error/byte]"
        },
        label: {
          rotation: -90
        }
      },
      {
        type: "log",
        tick: {
          count: 4
        },
        min: 0.00001
      }
    ]
  },
  PowerOnHours: {
    name: "PowerOnHours",
    title: {
      text: "Power on Hour Value Distributions"
    },
    axes: [
      {
        title: {
          text: "POH [hours]"
        }
      },
      {
        tick: {
          count: 5
        }
      }
    ]
  },
  ReassignEvent: {
    name: "ReassignEvent",
    title: {
      text: "Reassign Event Distributions"
    },
    axes: [
      {
        title: {
          text: "Counter"
        }
      },
      {
        type: "log",
        tick: {
          count: 3
        },
        min: 0.0001,
        max: 1
      }
    ]
  },
  ServoEvent: {
    name: "ServoEvent",
    title: {
      text: "Servo Event Distributions"
    },
    axes: [
      {
        title: {
          text: "Counter"
        }
      },
      {
        type: "log",
        tick: {
          count: 3
        },
        min: 0.0001,
        max: 1
      }
    ]
  },
  ShockSensor: {
    name: "ShockSensor",
    title: {
      text: "Shock Sensor Distributions"
    },
    axes: [
      {
        title: {
          text: "Counter"
        },
        tick: {
          count: 5
        }
      },
      {
        type: "log",
        tick: {
          count: 3
        },
        min: 0.0001
      }
    ]
  },
  ShockSensorNormalized: {
    name: "ShockSensorNormalized",
    title: {
      text: "Shock Sensor Distributions [Normalized]"
    },
    axes: [
      {
        type: "log",
        tick: {
          count: 4
        },
        title: {
          text: "Error Counter per byte written"
        },
        label: {
          rotation: -90
        }
      },
      {
        type: "log",
        tick: {
          count: 3
        },
        min: 0.0000001
      }
    ]
  },
  Temperature: {
    name: "Temperature",
    legend: {
      enabled: false
    },
    title: {
      text: "Temperature Distributions"
    },
    axes: [
      {
        title: {
          text: "Degrees"
        },
        label: {
          format: "#{0>2.0f} \xB0C"
        }
      },
      {}
    ]
  },
  Workload: {
    name: "Workload",
    title: {
      text: "Workload Distributions"
    },
    axes: [
      {
        type: "log",
        tick: {
          count: 5
        },
        min: 0.0001,
        max: 1000,
        title: {
          text: "TByte"
        },
        label: {
          rotation: -90
        }
      },
      {
        tick: {
          count: 5
        }
      }
    ]
  }
};
