import storeExtensions from "@/plugins/store-extensions";

export const lineIntegrationModule = {
  namespaced: true,
  state: {
    axios: storeExtensions.createAxios(), // Needed for different baseUrl
    file: {},
    files: [],
    previewFiles: []
  },
  getters: {
    axios: state => state.axios,
    file: state => state.file,
    files: state => state.files,
    previewFiles: state => state.previewFiles
  },
  mutations: {
    updateFile(state, newFile) {
      state.file = newFile;
    },
    updateFiles(state, newFiles) {
      state.files = newFiles;
    },
    updatePreviewFiles(state, newFiles) {
      state.previewFiles = newFiles;
    }
  },
  actions: {
    ...storeExtensions.actions,
    getPreviewFiles: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/view/files/search`,
        mutation: "updatePreviewFiles",
        payload: {
          ...payload,
          ...{ dataType: "SI Line Integration Data", excludeJSON: true }
        },
        label: `Files`
      }),
    getFile: (context, payload) =>
      context.dispatch("getApiData", {
        url: `api/view/file/id/${payload}`,
        mutation: "updateFile",
        label: `File`
      }),
    getFilteredFiles: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/view/files/search`,
        mutation: "updateFiles",
        payload: { ...payload, ...{ dataType: "SI Line Integration Data" } },
        label: `Files`
      })
  }
};
