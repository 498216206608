<template>
  <v-container>
    <v-row>
      <v-col cols="12" lg="8">
        <div class="dashboard-app blue darken-1">
          <v-icon dark>fa-th</v-icon>
          <router-link to="/view">
            <div>View Data</div>
          </router-link>
        </div>
      </v-col>
    </v-row>
    <v-row v-if="onAdminServer">
      <v-col cols="12" lg="8">
        <div class="dashboard-app blue darken-1">
          <v-icon dark>fa-plus-circle</v-icon>
          <router-link to="/upload">
            <div>Upload Data</div>
          </router-link>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    onAdminServer() {
      return [
        "uls-ep-mid01.ad.shared",
        "midinternal.ad.shared",
        "midinternal.wdc.com"
      ].includes(location.hostname);
    }
  }
};
</script>

<style lang="scss"></style>
