<template>
  <div>
    <v-overlay opacity="0.25" :value="loading" style="z-index:20001;">
      <v-progress-circular
        indeterminate
        color="#2A3F54"
        size="75"
      ></v-progress-circular>
    </v-overlay>
    <page-filters
      @filter-stickied="filterStickied = $event"
      @filter-expanded="filterExpanded = $event"
      small-viewport-height="300px"
    >
      <div slot="filterItems" class="filter-items-content">
        <v-row class="mx-5">
          <v-col cols="12" md="4" lg="3">
            <v-select
              v-model="product.selected"
              :disabled="filterStickied && !filterExpanded"
              :items="products"
              label="Select Product"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" md="4" lg="3">
            <v-select
              v-model="customer.selected"
              :disabled="filterStickied && !filterExpanded"
              :items="soldToCustomer"
              label="Sold to Customer"
              dense
            ></v-select>
          </v-col>
          <v-col
            v-if="!smallScreenSize && (!filterStickied || filterExpanded)"
            cols="12"
            md="4"
          ></v-col>
          <v-col cols="12" md="4" lg="3">
            <date-selector
              :format="format"
              data-type="lineIntegration"
              :disabled="filterStickied && !filterExpanded"
              @date-range-changed="setDateRange"
            />
          </v-col>
          <v-col v-if="displayFilterActions" cols="12" md="4" class="d-flex">
            <v-btn @click="clearFilters">Clear Filters</v-btn>
            <v-btn class="success ml-3" @click="fetchData">Apply Filters</v-btn>
          </v-col>
        </v-row>
      </div>
    </page-filters>
    <v-row class="ma-5">
      <v-col class="d-flex justify-end ma-0">
        <p class="font-italic">Last Updated: {{ lastModifiedDate }}</p>
      </v-col>
      <v-col cols="12">
        <div>
          <line-returns-chart
            title="First Level Failure Analysis"
            data-key="l1ErrorCodes"
            :format="format"
            :product="product.applied"
            :row-data="rowDataFiltered"
            class="border-right"
          />
        </div>
      </v-col>
      <v-col cols="12">
        <div>
          <line-returns-chart
            title="Second Level Failure Analysis"
            data-key="fa2"
            :format="format"
            :product="product.applied"
            :row-data="rowDataFiltered"
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-5">
      <v-col cols="12">
        <v-skeleton-loader :loading="rowDataLoading" type="image">
          <returned-drives-head-types-chart
            :format="format"
            :product="product.applied"
            :row-data="rowDataFiltered"
          />
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-row class="ma-5">
      <v-col cols="12">
        <v-skeleton-loader
          :loading="rowDataLoading || !rowDataFiltered"
          type="table"
        >
          <line-returns-grid :format="format" :row-data="rowDataFiltered" />
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from "dayjs";
import LineReturnsChart from "../LineReturns/LineReturnsChart.vue";
import LineReturnsGrid from "../LineReturns/LineReturnsGrid.vue";
import ReturnedDrivesHeadTypeChart from "../LineReturns/ReturnedDrivesHeadTypeChart.vue";
import PageFilters from "../PageFilters.vue";
import DateSelector from "../DateSelector.vue";

export default {
  name: "Line Returns",
  props: ["format"],
  mixins: [],
  components: {
    "line-returns-chart": LineReturnsChart,
    "line-returns-grid": LineReturnsGrid,
    "returned-drives-head-types-chart": ReturnedDrivesHeadTypeChart,
    PageFilters,
    DateSelector
  },
  data: () => ({
    loading: false,
    product: {
      selected: "",
      applied: ""
    },
    customer: {
      selected: "View All",
      applied: "View All"
    },
    selectedDateRange: {
      name: "Last 365 Days",
      dates: [
        dayjs()
          .startOf("day")
          .subtract(1, "years"),
        dayjs()
      ]
    },
    filterStickied: false,
    filterExpanded: false
  }),
  created() {},
  computed: {
    smallScreenSize() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    filterItemWidth() {
      return 3;
    },
    displayFilterActions() {
      return !this.filterStickied || this.filterExpanded;
    },
    dataType() {
      return this.$store.getters["dataTypesModule/dataTypes"].find(
        x => x.name == "SI Line Integration Data"
      );
    },
    dataRowGroups() {
      return this.$store.getters["lineIntegrationModule/files"].filter(
        x => x.format == this.format && x.dataTypeId === this.dataType.id
      );
    },
    rowData() {
      let data = [];
      this.dataRowGroups.forEach((dataGroup, index) => {
        let dataDate = dataGroup.dataDate;
        let dataGroupData = dataGroup.data || [];
        let rows = dataGroupData.map(row => ({
          ...row,
          dataDate: new Date(dataDate.replace(/-/g, "/"))
            .toISOString()
            .substr(0, 7)
        }));
        data = [...data, ...rows];
      });
      return data;
    },
    rowDataFiltered() {
      if (
        this.product.applied == "View All" &&
        this.customer.applied == "View All"
      ) {
        return this.rowData;
      } else {
        if (this.product.applied == "View All") {
          return this.rowData.filter(
            x => x.soldToCustomer == this.customer.applied
          );
        } else if (this.customer.applied == "View All") {
          return this.rowData.filter(x => x.product == this.product.applied);
        } else {
          return this.rowData.filter(
            x =>
              x.product == this.product.applied &&
              x.soldToCustomer == this.customer.applied
          );
        }
      }
    },
    products() {
      return [
        ...new Set(this.rowData.map(row => row.product)),
        "View All"
      ].sort();
    },
    soldToCustomer() {
      var customers = [
        ...new Set(this.rowData.map(row => row.soldToCustomer))
      ].sort();
      customers.push("View All");
      return customers;
    },
    lastModifiedDate() {
      let dates = this.$store.getters["lineIntegrationModule/previewFiles"]
        .filter(x => x.format == this.format)
        .map(file => dayjs(file.createdDate));
      return dates.length
        ? dates.reduce((a, b) => (a > b ? a : b)).format("MM/DD/YYYY")
        : "";
    }
  },
  methods: {
    setDateRange(dates) {
      this.selectedDateRange = dates;
    },
    async fetchData() {
      this.product.applied = this.product.selected;
      this.customer.applied = this.customer.selected;
      this.loading = true;
      await this.$store
        .dispatch("lineIntegrationModule/getFilteredFiles", {
          format: this.format,
          startDate: this.selectedDateRange.dates[0].format("YYYY-MM-DD"),
          endDate: this.selectedDateRange.dates[1].format("YYYY-MM-DD")
        })
        .catch(error => {
          this.$framework.showDialog(
            "",
            `<div class="error--text">${error}</div>`,
            "error"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFilters() {
      this.product.selected = this.products[0];
      this.customer.selected = "View All";
    }
  },
  beforeMount() {
    this.$store.dispatch("lineIntegrationModule/getPreviewFiles");
  },
  async mounted() {},
  watch: {
    products: {
      handler: function(newValue, oldValue) {
        if (newValue) {
          if (
            !this.product.selected ||
            !newValue.includes(this.product.selected)
          ) {
            this.product.selected = newValue[0];
            this.product.applied = newValue[0];
          }
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss">
.dppm-content .v-window__container {
  height: 100% !important;
}
.dppm-content .v-window-item {
  height: 100% !important;
}
</style>
