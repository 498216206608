<template>
  <ag-grid-vue
    :id="'agGridExSmart_' + format"
    ref="agGridExSmart"
    style="width: 100%"
    class="elevation-2"
    :style="{ height: gridHeight }"
    :class="{
      'ag-theme-alpine-dark': $vuetify.theme.dark,
      'ag-theme-alpine': !$vuetify.theme.dark
    }"
    :modules="allModules"
    :gridOptions="gridOptions"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    @grid-ready="onGridReady"
  >
  </ag-grid-vue>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

export default {
  name: "ExSmart Grid",
  props: {
    format: {
      type: String,
      required: true
    },
    pageFilters: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  mixins: [],
  components: {},
  data: () => ({
    updateLoading: false,
    gridHeight: "700px",
    gridOptions: {
      rowModelType: "serverSide",
      serverSideStoreType: "partial",
      cacheBlockSize: 1000, //Lazy-loading: with the grid options property: cacheBlockSize = 100 data will be fetched in blocks of 100 rows at a time.
      maxBlocksInCache: 10, //to limit the amount of data cached in the grid you can set maxBlocksInCache via the gridOptions.
      defaultColDef: null,
      sideBar: true,
      enableRangeSelection: true,
      enableCharts: true
    },
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      enableRowGroup: true
    },
    gridApi: null,
    columnApi: null
  }),
  computed: {
    dataType() {
      return this.$store.getters["dataTypesModule/dataTypes"].find(
        x => x.name == "exSmart"
      );
    },
    columnDefs() {
      return (
        this.dataType?.schema
          .filter(
            x =>
              !x.gridSettings.hide ||
              this.dataTypeSettings?.includes(x.cleanName)
          )
          .map(x => {
            x.gridSettings.headerName = x.cleanName;
            x.gridSettings.headerTooltip = x.csvHeader;
            return x.gridSettings;
          }) || []
      );
    },
    dataTypeSettings() {
      return this.$store.getters["userSettingsModule/selectedFields"].find(
        item => item.itemId == this.dataType.id
      )?.data;
    }
  },
  methods: {
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApiLocation?.hideOverlay(); //move to after data has been loaded
      //this.gridApi.setRowData(this.rowData);
      this.columnApi.autoSizeAllColumns();
      var datasource = this.ServerSideDatasource();
      this.gridApi.setServerSideDatasource(datasource);
    },
    formatFilterModel(filters) {
      var filterStructure = [];
      for (let [filterField, value] of Object.entries(filters)) {
        let innerFilter = {
          head: {
            fieldName: filterField,
            operator: value.operator || ""
          },
          conditions: []
        };
        if (value.operator) {
          if (value.condition1) innerFilter.conditions.push(value.condition1);
          if (value.condition2) innerFilter.conditions.push(value.condition2);
        } else {
          innerFilter.conditions.push(value);
        }
        filterStructure.push(innerFilter);
      }
      return filterStructure;
    },
    ServerSideDatasource() {
      return {
        getRows: async params => {
          var request = params.request;
          request = { ...request, ...this.pageFilters };
          //update the original filter model with a better format for processing
          request.filterModel = this.formatFilterModel(request.filterModel);
          setTimeout(() => {
            this.$store.dispatch("exSmartModule/getAgGridData", request).then(
              success => {
                params.success({
                  rowData: success.data,
                  rowCount: success.totalCount
                });
              },
              error => {
                params.fail();
                this.$framework.showDialog("Error", error, "error");
              }
            );
          }, 500);
        }
      };
    }
  },
  watch: {
    //when new filters are applied, reload the grid with new filters
    pageFilters(newValue, oldValue) {
      if (newValue != oldValue) {
        this.gridApi.refreshServerSideStore();
      }
    }
  }
};
</script>

<style lang="scss">
.wd-badge {
  color: cyan;
  margin-right: 4px;
  // border-radius: 25px;
  // padding: 4px 10px;
  // border: 1px solid limegreen;
}
.theme--light .wd-badge {
  color: dodgerblue;
}
.v-data-table.wd-simple-table-sm {
  th {
    font-size: 0.8375em !important;
  }
  th,
  td {
    font-weight: 300;
  }
}
.wd-ag-theme-alpine {
  transition: height 0.3s ease-out;
}

.v-window-item {
  height: 400px !important;
}

.content-divider {
  padding: 5px;
  cursor: ns-resize;
  background-color: #f8f8f8;
}

.content-divider-dark {
  padding: 5px;
  cursor: ns-resize;
  background-color: black;
}

.content-handle {
  width: 20px;
  border-top: 1px solid #bbb;
  text-align: center;
  margin: 2px auto 1px auto;
}

.ag-theme-alpine-dark .matrix-cell {
  border-right-color: rgba(88, 86, 82, 0.5) !important;
  border-right-width: 1px;
}

.ag-theme-alpine .matrix-cell {
  border-right-color: #dde2eb !important;
  border-right-width: 1px;
}

.ag-theme-alpine-dark .ag-ltr .ag-cell-focus,
.ag-theme-alpine .ag-ltr .ag-cell-focus {
  border-right-color: #2196f3 !important;
}

.ag-theme-alpine-dark .ag-ltr .ag-cell-range-selected,
.ag-theme-alpine .ag-ltr .ag-cell-range-selected {
  border-right-color: #2196f3 !important;
}

.ag-theme-alpine-dark .has-error,
.ag-theme-alpine .has-error {
  border-color: red !important;
}

.ag-theme-alpine-dark .is-copied,
.ag-theme-alpine .is-copied {
  border: 1px dashed #bbb !important;
}
</style>
