var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"parentContainer"},[_c('div',{ref:"moduleFilters",class:{
      sticky: _vm.stickied,
      'module-filters': true
    },style:({
      height:
        _vm.stickied && !_vm.expanded
          ? '70px'
          : _vm.smallScreenSize
          ? _vm.smallViewportHeight
          : _vm.initialHeight,
      width: _vm.toolbarWidth
    })},[_vm._t("filterItems"),(_vm.stickied && !_vm.disableExpansion)?_c('div',{staticClass:"filter-chevron"},[_c('v-btn',{attrs:{"icon":""}},[_c('v-icon',{on:{"click":_vm.onClick}},[_vm._v("fa "+_vm._s(_vm.expanded ? "fa-chevron-circle-up" : "fa-chevron-circle-down"))])],1)],1):_vm._e()],2),_c('div',{staticClass:"sticky-spacer"})])
}
var staticRenderFns = []

export { render, staticRenderFns }