<template>
  <v-container fluid>
    <v-overlay opacity="0.50" :value="updateLoading" style="z-index:20001;">
      <v-progress-circular
        indeterminate
        color="#2A3F54"
        size="75"
      ></v-progress-circular>
    </v-overlay>
    <div v-show="!fullScreen">
      <v-dialog v-model="dialog" max-width="600px" persistent>
        <upload-data-form
          v-on:cancel-create="clearCloseForm"
          v-on:upload="validateAndUpload"
          :edited-item="editedItem"
          :key="resetKey"
          :show-seed-checkbox="showInitialSeed"
        >
        </upload-data-form>
      </v-dialog>
      <v-skeleton-loader
        :loading="contentLoading"
        type="table"
        class="mx-auto main-content"
      >
        <div class="d-flex align-center mb-5">
          <h1>Upload</h1>
          <v-spacer></v-spacer>
          <v-btn large class="success" @click="dialog = true">
            <v-icon left>fa-upload</v-icon>Upload Data
          </v-btn>
        </div>
        <v-tabs fixed-tabs icons-and-text>
          <v-tabs-slider></v-tabs-slider>
          <v-tab @click="tabChange({ format: 'HDD', dataType: 'DPPM Data' })">
            HDD DPPM
            <v-icon>far fa-hdd</v-icon>
          </v-tab>
          <v-tab
            @click="
              tabChange({ format: 'HDD', dataType: 'SI Line Integration Data' })
            "
          >
            HDD Line Integration
            <v-icon>far fa-hdd</v-icon>
          </v-tab>
          <v-tab @click="tabChange({ format: 'HDD', dataType: 'exSmart' })">
            HDD exSmart
            <v-icon>far fa-hdd</v-icon>
          </v-tab>
          <v-tab @click="tabChange({ format: 'SSD', dataType: 'DPPM Data' })">
            SSD DPPM
            <v-icon>far fa-hdd</v-icon>
          </v-tab>
          <v-tab
            disabled
            @click="
              tabChange({ format: 'SSD', dataType: 'SI Line Integration Data' })
            "
          >
            SSD Line Integration
            <v-icon>far fa-hdd</v-icon>
          </v-tab>
          <v-tab
            disabled
            @click="tabChange({ format: 'SSD', dataType: 'exSmart' })"
          >
            SSD exSmart
            <v-icon>far fa-hdd</v-icon>
          </v-tab>
          <v-tab-item>
            <v-divider></v-divider>
            <upload-data-table
              format="HDD"
              data-type="DPPM Data"
              v-on:view-data="viewData"
              v-on:reupload-data="reuploadData"
              v-on:retire-data="retireData"
              v-on:download-data="downloadData"
            ></upload-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-divider></v-divider>
            <upload-data-table
              format="HDD"
              data-type="SI Line Integration Data"
              v-on:view-data="viewData"
              v-on:reupload-data="reuploadData"
              v-on:retire-data="retireData"
              v-on:download-data="downloadData"
            ></upload-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-divider></v-divider>
            <upload-data-table
              format="HDD"
              data-type="exSmart"
              v-on:view-data="viewData"
              v-on:reupload-data="reuploadData"
              v-on:retire-data="retireData"
              v-on:download-data="downloadData"
            ></upload-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-divider></v-divider>
            <upload-data-table
              format="SSD"
              data-type="DPPM Data"
              v-on:view-data="viewData"
              v-on:reupload-data="reuploadData"
              v-on:retire-data="retireData"
              v-on:download-data="downloadData"
            ></upload-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-divider></v-divider>
            <upload-data-table
              format="SSD"
              data-type="SI Line Integration Data"
              v-on:view-data="viewData"
              v-on:reupload-data="reuploadData"
              v-on:retire-data="retireData"
              v-on:download-data="downloadData"
            ></upload-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-divider></v-divider>
            <upload-data-table
              format="SSD"
              data-type="exSmart"
              v-on:view-data="viewData"
              v-on:reupload-data="reuploadData"
              v-on:retire-data="retireData"
              v-on:download-data="downloadData"
            ></upload-data-table>
          </v-tab-item>
        </v-tabs>
      </v-skeleton-loader>
    </div>
    <v-row v-show="fullScreen" class="ma-5">
      <v-col v-if="previewDataDisplay" cols="12">
        <v-skeleton-loader :loading="previewDataLoading" type="table">
          <dppm-grid
            v-if="previewDataType == 'DPPM Data'"
            :format="previewDataFormat"
            :row-data="previewDataRows"
            :height="'900px'"
          />
          <line-returns-grid
            v-if="previewDataType == 'SI Line Integration Data'"
            :format="previewDataFormat"
            :row-data="previewDataRows"
            :height="'900px'"
          />
          <exsmart-grid
            v-if="previewDataType == 'exSmart'"
            :page-filters="exSmartDatesForGrid"
            :format="previewDataFormat"
            :row-data="previewDataRows"
            :height="'900px'"
          />
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import UploadDataTable from "../components/Upload/UploadDataTable";
import UploadDataForm from "../components/Upload/UploadDataForm";
import DPPMGrid from "../components/DPPM/DPPMGrid";
import LineReturnsGrid from "../components/LineReturns/LineReturnsGrid";
import ExSmartGrid from "../components/ExSmart/ExSmartGrid";

dayjs.extend(customParseFormat);

export default {
  name: "Upload Data",
  components: {
    "dppm-grid": DPPMGrid,
    "line-returns-grid": LineReturnsGrid,
    "exsmart-grid": ExSmartGrid,
    UploadDataTable,
    UploadDataForm
  },
  data: () => ({
    fullScreen: false,
    previewDataDisplay: false,
    previewDataLoading: false,
    previewDataType: "",
    previewDataFormat: "",
    previewDataRows: [],
    resetKey: 0,
    selectedTabData: {
      format: "HDD",
      dataType: "DPPM Data",
      dataTypeId: 1
    },
    contentLoading: true,
    dialog: false,
    updateLoading: false,
    exSmartDatesForGrid: {
      startDate: "",
      endDate: ""
    },
    editedItem: {
      id: 0,
      name: "",
      notes: "",
      format: "HDD",
      dataTypeId: 1,
      uploadedData: {},
      dataDate: new Date().toISOString(),
      userId: 0,
      user: "",
      initialSeed: false
    },
    file: null,
    fileName: "",
    uploaded: 0,
    showInitialSeed: false
  }),
  mounted() {
    window.addEventListener("keydown", e => {
      if (e.key == "Escape" && this.fullScreen) {
        this.toggleFullscreen();
      }
    });
  },
  async created() {
    await this.$store.dispatch("dataTypesModule/getAllDataTypes");
    this.getAllContent();
  },
  computed: {
    loggedInUser() {
      return this.$store.getters["userModule/user"];
    },
    dataTypes() {
      return this.$store.getters["dataTypesModule/dataTypes"];
    },
    editedItemDataType() {
      return this.dataTypes.find(x => x.id == this.editedItem.dataTypeId).name;
    }
  },
  methods: {
    tabChange(tab) {
      this.selectedTabData.format = tab.format;
      this.selectedTabData.dataType = tab.dataType;
      this.selectedTabData.dataTypeId = this.dataTypes.find(
        x => x.name == tab.dataType
      ).id;
    },
    getAllContent() {
      this.contentLoading = true;
      Promise.all([
        this.$store.dispatch("dppmModule/getPreviewFiles"),
        this.$store.dispatch("lineIntegrationModule/getPreviewFiles"),
        this.$store.dispatch("exSmartModule/getPreviewFiles")
      ]).finally(() => {
        this.contentLoading = false;
      });
    },
    resetItem() {
      this.editedItem.id = 0;
      this.editedItem.name = "";
      this.editedItem.notes = "";
      this.editedItem.uploadedData = {};
      this.editedItem.dataDate = new Date().toISOString();
      this.editedItem.initialSeed = false;
    },
    clearCloseForm() {
      this.dialog = false;
      this.resetKey++;
      this.resetItem();
    },
    reuploadData(item) {
      this.editedItem.id = item.id;
      this.editedItem.name = item.name;
      this.editedItem.notes = item.notes;
      this.editedItem.format = item.format;
      this.editedItem.dataTypeId = item.dataTypeId;
      this.editedItem.uploadedData = item.uploadedData;
      this.editedItem.dataDate = item.dataDate;
      this.dialog = true;
    },
    retireData(item) {
      var fileDate = item.dataDate.replace(/-/g, "/");
      this.$framework.showDialog({
        title: "Delete Data",
        text: `Are you sure you want to delete this dataset? ${item.format} ${
          item.dataType
        } for ${dayjs(fileDate).format("YYYY-MM")} will be deleted.`,
        icon: "warning",
        maxWidth: 720,
        buttons: [
          {
            color: "secondary",
            label: "Cancel",
            click: () => {
              this.$framework.hideDialog();
            }
          },
          {
            color: "success",
            label: "Confirm",
            click: () => {
              this.$framework.hideDialog();
              this.updateLoading = true;
              this.$store.dispatch("adminModule/retireFile", [item.id]).then(
                success => {
                  this.updateLoading = false;
                  this.getAllContent();
                  this.$framework.showDialog(
                    "Success",
                    "Successfully deleted data file.",
                    "success"
                  );
                },
                error => {
                  this.updateLoading = false;
                  this.$framework.showDialog(
                    "Error",
                    "Unable to delete data file. Please try again",
                    "error"
                  );
                }
              );
            }
          }
        ]
      });
    },
    validateAndUpload(file) {
      this.updateLoading = true;
      this.file = file;
      this.fileName = file.name;
      var dataToValidate = {
        format: this.editedItem.format,
        dataTypeId: this.editedItem.dataTypeId,
        dataDate: this.editedItem.dataDate
      };
      this.$store.dispatch("adminModule/validate", dataToValidate).then(
        success => {
          var data = success;
          if (data.id) {
            this.updateLoading = false;
            var fileDate = data.dataDate.replace(/-/g, "/");
            this.$framework.showDialog({
              title: "Data Already exists",
              text: `Are you sure you want to proceed with this dataset? Previous ${
                this.editedItem.format
              } ${this.editedItemDataType} for ${dayjs(fileDate).format(
                "YYYY-MM"
              )} will be retired.`,
              icon: "warning",
              maxWidth: 720,
              buttons: [
                {
                  color: "secondary",
                  label: "Cancel",
                  click: () => {
                    this.$framework.hideDialog();
                  }
                },
                {
                  color: "success",
                  label: "Confirm",
                  click: () => {
                    this.editedItem.id = data.id;
                    this.$framework.hideDialog();
                    this.uploadData();
                  }
                }
              ]
            });
          } else {
            this.uploadData();
          }
        },
        error => {
          this.$framework.showDialog("Error", error, "error");
        }
      );
    },
    uploadData() {
      this.editedItem.userId = this.loggedInUser.id;
      this.editedItem.user = this.loggedInUser.name;
      this.updateLoading = true;
      this.dialog = true;

      let payload = {
        id: this.editedItem.id,
        file: this.file,
        active: true,
        name: this.editedItem.name,
        notes: this.editedItem.notes,
        format: this.editedItem.format,
        dataTypeId: this.editedItem.dataTypeId,
        createdDate: this.editedItem.createdDate,
        userId: this.editedItem.userId,
        user: this.editedItem.user,
        dataDate: this.editedItem.dataDate,
        initialSeed: this.editedItem.initialSeed
      };

      let formData = new FormData();
      for (const [key, value] of Object.entries(payload)) {
        formData.append(key, value);
      }

      this.$store.dispatch("adminModule/addFile", formData).then(
        success => {
          this.updateLoading = false;
          this.clearCloseForm();
          this.getAllContent();
          this.$framework.showDialog(
            "Success",
            "Successfully saved data file.",
            "success"
          );
        },
        error => {
          this.updateLoading = false;
          this.$framework.showDialog("Error", error, "error");
        }
      );
    },
    async downloadData(item) {
      this.updateLoading = true;
      await this.$store.dispatch("adminModule/downloadFileData", item.id).then(
        response => {
          this.updateLoading = false;
          let filename = response.headers["content-disposition"]
            .split(";")
            .find(n => n.includes("filename="))
            .replace("filename=", "")
            .trim();
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(new Blob([response.data]));
          link.setAttribute("download", filename);
          link.click();
        },
        error => {}
      );
    },
    viewData(item) {
      this.toggleFullscreen();
      this.previewDataRows = [];
      this.previewDataType = item.dataType;
      this.previewDataFormat = item.format;
      this.previewDataDisplay = true;
      this.exSmartDatesForGrid = {};
      if (["DPPM Data", "SI Line Integration Data"].includes(item.dataType)) {
        this.previewDataLoading = true;
        var module =
          item.dataType == "DPPM Data" ? "dppmModule" : "lineIntegrationModule";
        Promise.all([
          this.$store.dispatch(`${module}/getFile`, item.id)
        ]).finally(() => {
          this.previewDataRows = this.$store.getters[`${module}/file`].data;
          this.previewDataLoading = false;
        });
      } else {
        this.exSmartDatesForGrid.startDate = item.dataDate;
        this.exSmartDatesForGrid.endDate = dayjs(item.dataDate)
          .endOf("month")
          .format("MM-DD-YYYY");
      }
    },
    toggleFullscreen() {
      this.fullScreen = !this.fullScreen;
      this.$framework.model.drawer.show = !this.fullScreen;
      this.$framework.model.appbar.show = !this.fullScreen;

      if (this.fullScreen) {
        this.$framework.showSnackbar(
          "Press Esc to exit full screen mode.",
          "primary",
          5000,
          ["top", "middle"]
        );
      }
    }
  },
  watch: {
    selectedTabData: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.editedItem.format = newVal.format;
          this.editedItem.dataTypeId = newVal.dataTypeId;
        }
      },
      deep: true,
      immediate: true
    },
    loggedInUser: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.editedItem.userId = newVal.id;
          this.editedItem.user = newVal.name;
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.main-content .v-window__container {
  height: auto !important;
}
.main-content .v-window-item {
  height: auto !important;
}
</style>
