<template>
  <ag-grid-vue
    ref="agGridLocation"
    style="width: 100%"
    class="elevation-2"
    :style="{ height: gridHeight }"
    :class="{
      'ag-theme-alpine-dark': $vuetify.theme.dark,
      'ag-theme-alpine': !$vuetify.theme.dark
    }"
    :modules="allModules"
    :gridOptions="gridOptions"
    :columnDefs="columnDefs"
    :defaultColDef="defaultColDef"
    @grid-ready="onGridReady"
  >
  </ag-grid-vue>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

export default {
  name: "DPPM Grid",
  props: ["format", "rowData"],
  mixins: [],
  components: {},
  data: () => ({
    updateLoading: false,
    gridHeight: "700px",
    gridOptions: {
      defaultColDef: null,
      sideBar: true,
      enableRangeSelection: true,
      enableCharts: true
    },
    defaultColDef: {
      sortable: true,
      resizable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      enableRowGroup: true,
      flex: 1
    },
    gridApi: null,
    columnApi: null
  }),
  created() {},
  computed: {
    dataType() {
      return this.$store.getters["dataTypesModule/dataTypes"].find(
        x => x.name == "DPPM Data"
      );
    },
    columnDefs() {
      let defs = this.dataType?.schema.map(x => x.gridSettings) || [];
      return defs;
    }
  },
  methods: {
    async onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.gridApi.hideOverlay(); //move to after data has been loaded
      this.gridApi.setRowData(this.rowData);
    }
  },
  watch: {
    rowData(newValue, oldValue) {
      if (newValue != oldValue) {
        this.gridApi.setRowData(this.rowData);
      }
    }
  }
};
</script>

<style lang="scss">
.wd-badge {
  color: cyan;
  margin-right: 4px;
  // border-radius: 25px;
  // padding: 4px 10px;
  // border: 1px solid limegreen;
}
.theme--light .wd-badge {
  color: dodgerblue;
}
.v-data-table.wd-simple-table-sm {
  th {
    font-size: 0.8375em !important;
  }
  th,
  td {
    font-weight: 300;
  }
}
.wd-ag-theme-alpine {
  transition: height 0.3s ease-out;
}

.v-window-item {
  height: 400px !important;
}

.content-divider {
  padding: 5px;
  cursor: ns-resize;
  background-color: #f8f8f8;
}

.content-divider-dark {
  padding: 5px;
  cursor: ns-resize;
  background-color: black;
}

.content-handle {
  width: 20px;
  border-top: 1px solid #bbb;
  text-align: center;
  margin: 2px auto 1px auto;
}

.ag-theme-alpine-dark .matrix-cell {
  border-right-color: rgba(88, 86, 82, 0.5) !important;
  border-right-width: 1px;
}

.ag-theme-alpine .matrix-cell {
  border-right-color: #dde2eb !important;
  border-right-width: 1px;
}

.ag-theme-alpine-dark .ag-ltr .ag-cell-focus,
.ag-theme-alpine .ag-ltr .ag-cell-focus {
  border-right-color: #2196f3 !important;
}

.ag-theme-alpine-dark .ag-ltr .ag-cell-range-selected,
.ag-theme-alpine .ag-ltr .ag-cell-range-selected {
  border-right-color: #2196f3 !important;
}

.ag-theme-alpine-dark .has-error,
.ag-theme-alpine .has-error {
  border-color: red !important;
}

.ag-theme-alpine-dark .is-copied,
.ag-theme-alpine .is-copied {
  border: 1px dashed #bbb !important;
}
</style>
