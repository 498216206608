<template>
  <div>
    <v-dialog v-model="customDateDialog" persistent width="340px">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                readonly
                label="Date Range"
                v-model="customDateRangeText"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-date-picker
                type="month"
                :max="maxMonthYear"
                v-model="customDates"
                @change="resetDateRange"
                no-title
                range
                scrollable
              >
              </v-date-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="customDateDialog = false">
            Cancel
          </v-btn>
          <v-btn text color="primary" @click="saveCustomDates">
            Apply
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-select
      :disabled="disabled"
      v-model="selectedDateRange"
      dense
      :items="selectableDateRanges"
      item-text="name"
      return-object
      :menu-props="{ offsetY: true }"
      @change="changeDateRange"
    >
      <template v-slot:item="{ item }">
        <v-list-item
          v-if="item.name == 'Custom'"
          @click="customDateDialog = true"
        >
          <v-list-item-content>
            <v-list-item-title> Custom Date Range </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item-title v-else v-text="item.name"></v-list-item-title>
      </template>
    </v-select>
  </div>
</template>

<script>
import dayjs from "dayjs";

const dateRanges = [
  {
    name: "Custom"
  },
  {
    name: "Last 365 Days",
    dateRange: [
      dayjs()
        .startOf("day")
        .subtract(1, "years"),
      dayjs()
    ]
  },
  {
    name: "This Month",
    dateRange: [dayjs().startOf("month"), dayjs().endOf("month")]
  },
  {
    name: "Last Month",
    dateRange: [
      dayjs()
        .subtract(1, "month")
        .startOf("month"),
      dayjs()
        .subtract(1, "month")
        .endOf("month")
    ]
  }
];

export default {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    dataType: {
      type: String,
      required: true
    },
    format: {
      type: String,
      required: true
    }
  },
  data: () => ({
    maxMonthYear: dayjs().format("YYYY-MM"),
    customDateDialog: false,
    customDates: [],
    dateRanges: dateRanges,
    selectedDateRange: {
      name: "Last 365 Days",
      dates: [
        dayjs()
          .startOf("day")
          .subtract(1, "years"),
        dayjs()
      ]
    }
  }),
  computed: {
    selectedDateRangeName() {
      return this.selectedDateRange.name;
    },
    customDateRangeText() {
      return this.customDates.join(" - ");
    },
    selectableDateRanges() {
      var dataFilesDateRanges = this.$store.getters[
        `${this.dataType}Module/previewFiles`
      ]
        .filter(x => x.format == this.format)
        .map(function(file) {
          var dateFileDate = file.dataDate.replace(/-/g, "/");
          var start = dayjs(dateFileDate).startOf("month");
          var end = dayjs(dateFileDate).endOf("month");
          var name = dayjs(dateFileDate).format("MMMM YYYY");
          return {
            name: name,
            date: dayjs(dateFileDate).format("YYYY-MM"),
            dateRange: [start, end]
          };
        })
        .sort((a, b) => new Date(b.date) - new Date(a.date));
      return [...this.dateRanges, ...dataFilesDateRanges];
    },
    dateRangeText() {
      return this.selectedDateRange.dates
        .map(date => {
          return date.format("YYYY-MM");
        })
        .join(" - ");
    }
  },
  methods: {
    changeDateRange() {
      this.selectedDateRange.dates = this.selectedDateRange.dateRange;
      this.$emit("date-range-changed", this.selectedDateRange);
    },
    resetDateRange() {
      // Don't set range if start date is after end date
      if (dayjs(this.customDates[0]).isAfter(this.customDates[1])) {
        this.customDates = [this.customDates[1]];
      }
    },
    saveCustomDates() {
      this.selectedDateRange = {
        name: "Custom",
        dates: [dayjs(this.customDates[0]), dayjs(this.customDates[1])]
      };
      this.customDateDialog = false;
      this.$emit("date-range-changed", this.selectedDateRange);
    }
  }
};
</script>
