<template>
  <div style="height: 600px" class="py-10 mb-5">
    <template v-if="statusReady && dataGrouped.length > 0">
      <v-row class="ma-5" align="right" justify="end">
        <v-col cols="2" class="column-chart-selector">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="ml-1 my-1 text-none font-weight-light text-body-1"
                :color="$vuetify.theme.dark ? 'secondary' : null"
                v-bind="attrs"
                v-on="on"
              >
                <div class="text-left text-truncate" style="width: 120px">
                  {{
                    columnChartOptions.items.find(
                      o => o.value == columnChartOptions.currentView
                    ).text
                  }}
                </div>
                <v-icon x-small right>fa-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="item in columnChartOptions.items"
                :key="item.value"
                @click="columnChartOptions.currentView = item.value"
              >
                <v-list-item-title>{{ item.text }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
      <ag-charts-vue
        class="pb-3"
        :options="barChartOptions"
        v-if="statusReady"
      />
    </template>
    <v-alert v-else :value="true" type="info">
      No Bar Chart to display
    </v-alert>
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { AgChartsVue } from "ag-charts-vue";
export default {
  name: "Returned Drives Head Types Chart",
  props: ["format", "product", "rowData"],
  components: {
    AgChartsVue
  },
  data: () => ({
    columnChartOptions: {
      currentView: "NormalView",
      items: [
        { value: "NormalView", text: "Column View" },
        { value: "StackedView", text: "Stacked Column View" }
      ]
    },
    stackedCheckbox: false,
    chartStyle: {
      fontColor: {
        dark: "#eee",
        light: "#444"
      }
    },
    statusReady: false
  }),
  computed: {
    chartFontStyle() {
      return {
        color: this.chartStyle.fontColor[
          this.$vuetify.theme.dark ? "dark" : "light"
        ],
        fontWeight: 300,
        fontFamily: "Roboto, sans-serif"
      };
    },
    barChartOptions() {
      return {
        autoSize: true,
        title: {
          text: "Returned Drive Head Types by Manufacture Date",
          fontSize: 20,
          color: this.chartStyle.fontColor[
            this.$vuetify.theme.dark ? "dark" : "light"
          ]
        },
        data: this.dataGrouped,
        background: {
          visible: false
        },
        legend: {
          enabled: true,
          position: "right",
          item: {
            label: {
              color: this.chartStyle.fontColor[
                this.$vuetify.theme.dark ? "dark" : "light"
              ]
            }
          }
        },
        series: this.chartSeries,
        axes: [
          {
            type: "category",
            position: "bottom",
            title: {
              enabled: true,
              text: "Manufacture Date",
              color: this.chartStyle.fontColor[
                this.$vuetify.theme.dark ? "dark" : "light"
              ]
            },
            label: {
              color: this.chartStyle.fontColor[
                this.$vuetify.theme.dark ? "dark" : "light"
              ]
            }
          },
          {
            type: "number",
            position: "left",
            title: {
              enabled: true,
              text: "Count of returned drive head types",
              color: this.chartStyle.fontColor[
                this.$vuetify.theme.dark ? "dark" : "light"
              ]
            },
            label: {
              formatter: params => {
                return params.value;
              }
            }
          }
        ]
      };
    },
    dataType() {
      return this.$store.getters["dataTypesModule/dataTypes"].find(
        x => x.name == "SI Line Integration Data"
      );
    },
    dataGrouped() {
      var dates = [...new Set(this.rowData.map(row => row.mainAsmYearMonth))];
      var itemDateGroups = [];
      var sliderUpTypes = [...new Set(this.rowData.map(row => row.hgaupSupp))];
      dates.forEach((yearMonth, index) => {
        var dateAndSliderUpCounts = {
          date: yearMonth
        };
        var filteredItemsForProduct = this.rowData.filter(
          x => x.mainAsmYearMonth === yearMonth
        );

        sliderUpTypes.forEach((sliderUpType, index) => {
          var filteredRowsForSliderUpType = filteredItemsForProduct.filter(
            x => x.hgaupSupp == sliderUpType
          );
          var count =
            filteredRowsForSliderUpType.length > 0
              ? filteredRowsForSliderUpType.length
              : "";
          dateAndSliderUpCounts[sliderUpType] = count;
        });
        itemDateGroups.push(dateAndSliderUpCounts);
      });

      if (itemDateGroups) {
        itemDateGroups = itemDateGroups.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
      }

      return itemDateGroups;
    },
    uniqueSliderupSupplyValues() {
      return [...new Set(this.rowData.map(row => row.hgaupSupp))];
    },
    chartSeries() {
      return [
        {
          type: "column",
          xKey: "date",
          yKeys: this.uniqueSliderupSupplyValues,
          grouped: !this.stackedCheckbox,
          tooltip: {
            renderer: params => {
              return {
                title: `${params.xValue} - ${params.yKey}`,
                content: `Total: ${params.datum[params.yKey]}`
              };
            }
          }
        }
      ];
    }
  },
  async mounted() {
    this.statusReady = true;
  },
  async beforeMount() {},

  methods: {},
  watch: {
    columnChartOptions: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.stackedCheckbox = newVal.currentView == "StackedView";
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss">
.toolPanel {
  text-align: center;
  font-size: 12px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
.stacked-checkbox .v-input__slot {
  text-align: center;
  display: block;
}
.column-chart-selector {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
