import Vue from "vue";
import Vuex from "vuex";

import { adminModule } from "./modules/adminModule";
import { userModule } from "./modules/userModule";
import { dppmModule } from "./modules/dppmModule";
import { lineIntegrationModule } from "./modules/lineIntegrationModule";
import { exSmartModule } from "./modules/exSmartModule";
import { dataTypesModule } from "./modules/dataTypesModule";
import { userSettingsModule } from "./modules/userSettingsModule";

import storeExtensions from "@/plugins/store-extensions";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    adminModule,
    userModule,
    dppmModule,
    lineIntegrationModule,
    exSmartModule,
    dataTypesModule,
    userSettingsModule
  },
  state: {
    ...storeExtensions.state
  },
  mutations: {},
  getters: {},
  actions: {
    ...storeExtensions.actions,
    async initializeApp(context) {},
    FetchUserSpecificData: async ({ commit, dispatch }) => {
      var data =
        JSON.parse(localStorage.getItem(`${location.hostname}.user`)) || {};
      if (data && data.guid) {
        await axios
          .get(`api/user/get/guid/${data.guid}`)
          .then(u => {
            let user = {};
            if (u.data) {
              user = u.data;
              commit("userModule/updateUser", user);
            }
          })
          .catch(err => {});
      }
    }
  }
});
