<template>
  <v-container fluid>
    <v-overlay opacity="0.50" :value="updateLoading" style="z-index:20001;">
      <v-progress-circular
        indeterminate
        color="#2A3F54"
        size="75"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <create-user-form
        v-on:cancel-create="clearCloseForm"
        v-on:create="createNewUser"
        :user="newUser"
      >
      </create-user-form>
    </v-dialog>

    <div v-if="contentLoading">
      <v-skeleton-loader type="table" class="mx-auto"></v-skeleton-loader>
    </div>
    <div v-else>
      <div class="d-flex align-center mb-5">
        <h1>Users</h1>
        <v-spacer></v-spacer>
        <v-btn large class="success" @click="dialog = true">
          <v-icon left>fa-user</v-icon>Create User
        </v-btn>
      </div>
      <div>
        <users-data-table
          v-on:enable-user="enableUser"
          v-on:disable-user="disableUser"
          v-on:password-reset-request="passwordResetRequest"
        ></users-data-table>
      </div>
    </div>
  </v-container>
</template>

<script>
import UsersDataTable from "../components/Users/UsersDataTable";
import CreateUserForm from "../components/Users/CreateUserForm";

export default {
  name: "Users",
  components: {
    UsersDataTable,
    CreateUserForm
  },
  data: () => ({
    contentLoading: true,
    dialog: false,
    updateLoading: false,
    newUser: {
      name: "",
      emailAddress: "",
      createdBy: ""
    }
  }),
  created() {
    this.getAllUsers();
  },
  computed: {
    loggedInUser() {
      return this.$store.getters["userModule/user"];
    }
  },
  methods: {
    getAllUsers() {
      this.contentLoading = true;
      Promise.all([this.$store.dispatch("userModule/getAllUsers")]).finally(
        () => {
          this.contentLoading = false;
        }
      );
    },
    resetUser() {
      this.newUser.name = "";
      this.newUser.emailAddress = "";
    },
    clearCloseForm() {
      this.dialog = false;
      this.resetUser();
    },
    enableUser(user) {
      this.setUserState(user, 1);
    },
    disableUser(user) {
      this.setUserState(user, 0);
    },
    setUserState(user, active) {
      this.$framework.hideDialog();
      this.updateLoading = true;
      this.$store
        .dispatch("userModule/updateUser", {
          id: user.id,
          active: active,
          modifiedBy: this.newUser.createdBy
        })
        .then(
          success => {
            this.updateLoading = false;
            this.getAllUsers();
            this.$framework.showDialog(
              "Success",
              `Successfully ${active ? "enabled" : "disabled"} user.`,
              "success"
            );
          },
          error => {
            this.updateLoading = false;
            this.getAllUsers();
            this.$framework.showDialog(
              "Error",
              `Unable to ${
                active ? "enable" : "disable"
              } user. Please try again`,
              "error"
            );
          }
        );
    },
    passwordResetRequest(user) {
      this.updateLoading = true;
      this.$store
        .dispatch("userModule/sendUserPasswordResetEmail", { userId: user.id })
        .then(
          success => {
            this.updateLoading = false;
            this.$framework.showDialog(
              "Success",
              "Successfully sent user password reset request.",
              "success"
            );
          },
          error => {
            this.updateLoading = false;
            this.$framework.showDialog(
              "Error",
              "Unable to send password reset request to user. Please try again",
              "error"
            );
          }
        );
    },
    async createNewUser() {
      this.updateLoading = true;
      this.dialog = true;

      this.$store.dispatch("userModule/createUser", this.newUser).then(
        success => {
          this.updateLoading = false;
          this.clearCloseForm();
          this.getAllUsers();
          this.$framework.showDialog(
            "Success",
            "Successfully created new user.",
            "success"
          );
        },
        error => {
          var errorMessage = "";
          if (error.response.status === 409) {
            errorMessage = `User with email address already exists.`;
          } else {
            errorMessage =
              error.response.data.exceptionMessage || "Error. Please try again";
          }
          this.updateLoading = false;
          this.$framework.showDialog("Error", errorMessage, "error");
        }
      );
    }
  },
  watch: {
    loggedInUser: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.newUser.createdBy = newVal.name;
        }
      },
      immediate: true
    }
  }
};
</script>

<style lang="scss" scoped>
.main-content .v-window__container {
  height: auto !important;
}
.main-content .v-window-item {
  height: auto !important;
}
</style>
