<template>
  <div style="height: 420px" class="pb-10 mb-5">
    <ag-charts-vue
      v-if="statusReady && dataGrouped.length > 0"
      :options="lineChartOptions"
    />
    <v-alert v-else :value="true" type="info">
      No Line Chart to display
    </v-alert>
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { AgChartsVue } from "ag-charts-vue";
export default {
  name: "DPPM Chart",
  props: ["format", "rowData"],
  components: {
    AgChartsVue
  },
  data: () => ({
    chartStyle: {
      fontColor: {
        dark: "#eee",
        light: "#444"
      }
    },
    statusReady: false
  }),
  computed: {
    chartFontStyle() {
      return {
        color: this.chartStyle.fontColor[
          this.$vuetify.theme.dark ? "dark" : "light"
        ],
        fontWeight: 300,
        fontFamily: "Roboto, sans-serif"
      };
    },
    lineChartOptions() {
      return {
        title: {
          text: "DPPM By Location Chart",
          fontSize: 20,
          color: this.chartStyle.fontColor[
            this.$vuetify.theme.dark ? "dark" : "light"
          ]
        },
        data: this.dataGrouped,
        background: {
          visible: false
        },
        legend: {
          enabled: true,
          position: "right",
          item: {
            label: {
              color: this.chartStyle.fontColor[
                this.$vuetify.theme.dark ? "dark" : "light"
              ]
            }
          }
        },
        series: this.chartSeries,
        axes: [
          {
            title: {
              enabled: true,
              text: "Year-Month",
              color: this.chartStyle.fontColor[
                this.$vuetify.theme.dark ? "dark" : "light"
              ]
            },
            type: "category",
            nice: false,
            position: "bottom",
            label: { ...this.chartFontStyle },
            tick: {
              width: 0
            }
          },
          {
            title: {
              enabled: true,
              text: "DPPM",
              color: this.chartStyle.fontColor[
                this.$vuetify.theme.dark ? "dark" : "light"
              ]
            },
            type: "number",
            position: "left",
            label: { ...this.chartFontStyle }
          }
        ]
      };
    },
    chartSeries() {
      let items = [];
      this.uniqueVendorsWithLocation.forEach((vendorAndLocation, index) => {
        let newItem = {
          type: "line",
          xKey: "date",
          yKey: `${vendorAndLocation}-groupedDPPM`,
          yName: vendorAndLocation,
          tooltip: {
            renderer: params => {
              let totalIntegrations = params.datum[`${params.yName}-i`];
              let totalFailures = params.datum[`${params.yName}-f`];
              let groupedDPPM = params.datum[`${params.yName}-groupedDPPM`];
              return {
                title: params.yName,
                content: `<table style="font-size: 1em"><tr><td>Total Integrations:</td><td align="right">${totalIntegrations}</td></tr><tr><td>Total Failures:</td><td align="right">${totalFailures}</td></tr><tr><td>DPPM:</td><td align="right"><b>${groupedDPPM.toFixed(
                  2
                )}</b></td></tr></table>`
              };
            }
          }
        };
        items.push(newItem);
      });
      return items;
    },
    uniqueVendorsWithLocation() {
      return [...new Set(this.rowData.map(row => row.vendorLocationAgg))];
    },
    dataGrouped() {
      let dates = [...new Set(this.rowData.map(row => row.dataDate))];
      let itemDateGroups = [];
      dates.forEach((dataDate, index) => {
        let dateAndCounts = {
          date: new Date(dataDate.replace(/-/g, "/")).toISOString().substr(0, 7)
        };
        let filteredItemsForDate = this.rowData.filter(
          x => x.dataDate === dataDate
        );

        this.uniqueVendorsWithLocation.forEach((vendorAndLocation, index) => {
          let filteredItemsForDateAndVendor = filteredItemsForDate.filter(
            x => x.vendorLocationAgg == vendorAndLocation
          );
          let dppmCount = filteredItemsForDateAndVendor.reduce(
            (sum, current) => sum + current.dppm,
            0
          );
          let iCount = filteredItemsForDateAndVendor.reduce(
            (sum, current) => sum + current.i,
            0
          );
          let fCount = filteredItemsForDateAndVendor.reduce(
            (sum, current) => sum + current.f,
            0
          );
          //formula for DPPM(defective parts per million) calculation is: (failures/integrations) * 1,000,000
          let groupedDPPM = iCount ? (fCount / iCount) * Math.pow(10, 6) : 0;

          dateAndCounts[`${vendorAndLocation}-dppm`] = dppmCount;
          dateAndCounts[`${vendorAndLocation}-i`] = iCount;
          dateAndCounts[`${vendorAndLocation}-f`] = fCount;
          dateAndCounts[`${vendorAndLocation}-groupedDPPM`] = groupedDPPM;
        });
        itemDateGroups.push(dateAndCounts);
      });

      if (itemDateGroups) {
        itemDateGroups = itemDateGroups.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
      }
      return itemDateGroups;
    }
  },
  async mounted() {
    this.statusReady = true;
  },
  async beforeMount() {},

  methods: {},
  watch: {}
};
</script>

<style lang="scss"></style>
