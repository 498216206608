<template>
  <Framework v-model="frameworkSettings">
    <SystemSetup />
    <router-view :key="$route.fullPath" />
    <template v-slot:appbar-right>
      <v-menu offset-y transition="slide-y-transition">
        <template v-slot:activator="{ on }">
          <v-btn
            depressed
            tile
            x-large
            v-on="on"
            color="transparent"
            style="text-transform: none; letter-spacing: normal;"
          >
            <v-icon left style="font-size: 20px">fa-user-circle</v-icon>
            {{ loggedInUser.name || "Guest" }}
            <v-icon right x-small>fa-caret-down</v-icon>
          </v-btn>
        </template>

        <!-- User dropdown (logged in) -->
        <v-list min-width="160" v-if="loggedInUser.id">
          <!-- Logout -->
          <v-list-item @click="logOut">
            <v-list-item-icon>
              <v-icon>fa-sign-out-alt fa-fw</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Logout</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
  </Framework>
</template>

<script>
import Vue from "vue";
import SystemSetup from "./components/SystemSetup";

export default {
  components: {
    Framework: () => Vue.importHttp("framework/Framework"),
    SystemSetup
  },
  data() {
    return {
      frameworkSettings: {
        app: {
          name: "Microsoft Integration Dashboard",
          icon: "wdi-presentation-material-customer",
          color: "#607D8B",
          dark: true
        },
        theme: { dark: true },
        drawer: {
          mini: true,
          miniControl: false,
          items: [],
          roleItems: {
            admin: [
              {
                type: "v-list-item",
                items: [
                  { icon: "fa-home", text: "Home", to: "/" },
                  {
                    icon: "fas fa-sitemap",
                    text: "View All Data",
                    to: "/view"
                  },
                  { icon: "fas fa-upload", text: "Upload", to: "/upload" },
                  { icon: "fas fa-users", text: "Users", to: "/users" }
                ]
              },
              { type: "v-divider" }
            ],
            user: [
              {
                type: "v-list-item",
                items: [
                  { icon: "fa-home", text: "Home", to: "/" },
                  {
                    icon: "fas fa-sitemap",
                    text: "View All Data",
                    to: "/view"
                  }
                ]
              },
              { type: "v-divider" }
            ]
          }
        },
        appbar: {
          miniControl: true,
          control: {
            appsList: false,
            theme: true
          }
        }
      }
    };
  },
  methods: {
    logOut() {
      localStorage.removeItem(`${location.hostname}.user`);
      window.location.href = `/`;
    }
  },
  computed: {
    loggedInUser() {
      return this.$store.getters["userModule/user"];
    }
  },
  async mounted() {
    await this.$store.dispatch("FetchUserSpecificData");
  }
};
</script>

<style></style>
