import { AgGridVue } from "ag-grid-vue";
import { AllModules } from "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";

LicenseManager.setLicenseKey(
  "CompanyName=Betis Direct, LLC_on_behalf_of_WESTERN DIGITAL TECHNOLOGIES, INC,LicensedGroup=WD-WebSolutions,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=12,LicensedProductionInstancesCount=1,AssetReference=AG-012669,ExpiryDate=8_June_2022_[v2]_MTY1NDY0MjgwMDAwMA==25c11f47c035f7a54856925b774645aa"
);

export default {
  install(Vue) {
    Vue.component("ag-grid-vue", AgGridVue);
    Vue.mixin({
      data() {
        return { allModules: AllModules };
      }
    });
  }
};
