<template>
  <v-container fluid>
    <v-overlay opacity="0.50" :value="updateLoading" style="z-index:20001;">
      <v-progress-circular
        indeterminate
        color="#2A3F54"
        size="75"
      ></v-progress-circular>
    </v-overlay>
    <v-dialog v-model="dialog" max-width="600px" persistent>
      <v-container class="text-xs-center">
        <v-card>
          <v-card-title>Password Reset</v-card-title>
          <v-card-text>
            <v-form ref="form" v-model="isFormValid">
              <v-text-field
                v-model="password"
                :rules="rules.password"
                type="password"
                label="Password"
                required
                error-count="5"
              ></v-text-field>
              <v-text-field
                v-model="confirmPassword"
                :rules="[confirmPassword === password || 'Password must match']"
                type="password"
                label="Confirm Password"
                required
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="success"
              :disabled="!isFormValid"
              @click="resetUserPassword"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-container>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Reset Password",
  data: () => ({
    isFormValid: false,
    dialog: true,
    updateLoading: false,
    password: "",
    confirmPassword: "",
    rules: {
      password: [
        v => !!v || "Password is required",
        v => (v && v.length >= 8) || "Password must have 8+ characters",
        v => /(?=.*[A-Z])/.test(v) || "Must have one uppercase character",
        v => /(?=.*\d)/.test(v) || "Must have one number",
        v => /([!@$%])/.test(v) || "Must have one special character [!@#$%]"
      ]
    }
  }),
  computed: {},
  methods: {
    async resetUserPassword() {
      this.updateLoading = true;

      await this.$store
        .dispatch("userModule/resetUserPassword", {
          hash: this.$route.query.hash,
          password: this.password
        })
        .then(
          success => {
            this.updateLoading = false;
            var userData = success;
            this.$store.commit("userModule/updateUser", userData);
            localStorage.setItem(
              `${location.hostname}.user`,
              JSON.stringify({
                guid: userData.guid
              })
            );
            window.location.href = `/`;
          },
          error => {
            this.updateLoading = false;
            var errorMessage = "";
            if (error.response.status === 401) {
              errorMessage = `Invalid hash provided.<br/>If this issues continues please reach out to your WD contact.`;
            } else {
              errorMessage =
                error.response.data.exceptionMessage ||
                "Error. Please try again";
            }
            this.$framework.showDialog("Error", errorMessage, "error");
          }
        )
        .catch(error => {
          this.updateLoading = false;
          this.$framework.showDialog("Error", error, "error");
        });
    }
  },
  watch: {}
};
</script>

<style lang="scss"></style>
