<template>
  <div>
    <v-card>
      <v-card-title>Create New User</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid">
          <v-text-field
            v-model.trim="user.name"
            label="Name"
            required
          ></v-text-field>
          <v-text-field
            :rules="emailRules"
            v-model.trim="user.emailAddress"
            label="Email address"
            required
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="
            $emit('cancel-create');
            csv = null;
          "
        >
          <v-icon left>fa-times</v-icon>
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="!isFormValid"
          @click="$emit('create')"
        >
          <v-icon left>fa fa-upload</v-icon>Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object
    }
  },
  data: () => ({
    isFormValid: false,
    emailRules: [v => /.+@.+/.test(v) || "Invalid Email address"]
  }),
  computed: {},
  methods: {},
  watch: {}
};
</script>
<style>
.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  margin-top: 4px;
  margin: 4px 0 0;
}
</style>
