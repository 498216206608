<template>
  <div>
    <v-overlay opacity="0.25" :value="loading" style="z-index:20001;">
      <v-progress-circular
        indeterminate
        color="#2A3F54"
        size="75"
      ></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-card-title v-if="editedItem.id == 0"
        >Upload New Data File</v-card-title
      >
      <v-card-title v-else>Re-Upload Data File</v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="isFormValid">
          <v-text-field
            v-model="editedItem.name"
            label="File Name"
            required
          ></v-text-field>
          <v-select
            disabled
            :items="formats"
            v-model="editedItem.format"
            label="Drive Format"
          ></v-select>
          <v-select
            disabled
            :items="dataTypes"
            v-model="editedItem.dataTypeId"
            item-text="name"
            item-value="id"
            label="Data Type"
          ></v-select>
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dataDate"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            ><template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="dataDate"
                label="File Date"
                prepend-icon="fas fa-calendar-week"
                required
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              type="month"
              :max="maxMonthYear"
              v-model="dataDate"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(dataDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
          <v-row class="wrapper" align="center">
            <v-btn
              small
              :disabled="!editedItem.dataTypeId"
              @click="onPickFile"
              color="primary"
            >
              <v-icon left>fa-file-import</v-icon>
              <input
                type="file"
                style="display:none"
                ref="fileInput"
                class="form-control"
                accept="csvFile/*"
                @change="loadCSV"
              />
              Select CSV for Import
            </v-btn>
            <span v-if="csv">File Name : {{ csv.fileName }}</span>
          </v-row>
          <v-row class="wrapper" align="center">
            <span>
              File Size: <b>{{ csv ? csv.fileSize : "0 KB" }}</b> Total rows :
              <b>{{ csv && csv.rowCount ? csv.rowCount : "0" }}</b></span
            >
            <!--
            <v-btn small :disabled="!csv" @click="previewContentsDialog = true">
              <v-icon left>fa-file</v-icon>
              Preview CSV Contents
            </v-btn>
            -->
          </v-row>
          <v-textarea
            v-model="editedItem.notes"
            label="Notes"
            required
          ></v-textarea>
          <v-checkbox
            v-if="showSeedCheckbox"
            v-model="editedItem.initialSeed"
            label="Initial Seed"
          ></v-checkbox>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn
          @click="
            $emit('cancel-create');
            csv = null;
          "
        >
          <v-icon left>fa-times</v-icon>
          Cancel
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="success" :disabled="!file" @click="$emit('upload', file)">
          <v-icon left>fa fa-upload</v-icon>Upload
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="previewContentsDialog"
      max-width="1300px"
      scrollable
      persistent
    >
      <v-card align-center v-if="previewContentsDialog">
        <v-card-text>
          <dppm-grid
            v-if="dataTypeName == 'DPPM Data'"
            :format="editedItem.format"
            :row-data="editedItem.uploadedData"
            :height="'600px'"
          />
          <line-returns-grid
            v-if="dataTypeName == 'SI Line Integration Data'"
            :format="editedItem.format"
            :row-data="editedItem.uploadedData"
            :height="'600px'"
          />
          <exsmart-grid
            v-if="dataTypeName == 'exSmart'"
            :format="editedItem.format"
            :row-data="editedItem.uploadedData"
            :height="'600px'"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            v-on:click="previewContentsDialog = false"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DPPMGrid from "../DPPM/DPPMGrid.vue";
import LineReturnsGrid from "../LineReturns/LineReturnsGrid.vue";
import ExSmartGrid from "../ExSmart/ExSmartGrid.vue";

export default {
  components: {
    "dppm-grid": DPPMGrid,
    "line-returns-grid": LineReturnsGrid,
    "exsmart-grid": ExSmartGrid
  },
  props: {
    editedItem: Object,
    showSeedCheckbox: Boolean
  },
  data: () => ({
    isFormValid: false,
    maxMonthYear: dayjs().format("YYYY-MM"),
    loading: false,
    previewContentsDialog: false,
    csv: {
      fileName: null,
      fileSize: null,
      rowCount: 0
    },
    menu: false,
    formats: ["SSD", "HDD"],
    file: ""
  }),
  computed: {
    dataDate: {
      get: function() {
        if (this.editedItem.dataDate) {
          return new Date(this.editedItem.dataDate).toISOString().substr(0, 7);
        }
        return new Date().toISOString().substr(0, 7);
      },
      set: function(value) {
        var newDate = new Date(value).toISOString();
        this.editedItem.dataDate = newDate;
      }
    },
    dataTypes() {
      return this.$store.getters["dataTypesModule/dataTypes"];
    },
    dataTypeName() {
      return this.dataTypes.find(x => x.id === this.editedItem.dataTypeId).name;
    },
    dataTypeSchema() {
      return this.dataTypes.find(x => x.id === this.editedItem.dataTypeId)
        .schema;
    },
    dataTypeSchemaHeaderMapping() {
      var dataTypeSchemaObjects = {};
      this.dataTypeSchema.map(headerItem => {
        dataTypeSchemaObjects[headerItem.csvHeader] = headerItem;
      });
      return dataTypeSchemaObjects;
    },

    dataTypeCSVHeaders() {
      return this.dataTypes
        .find(x => x.id === this.editedItem.dataTypeId)
        .schema.map(a => a.csvHeader);
    }
  },
  methods: {
    onPickFile() {
      this.editedItem.file = {};
      this.$refs.fileInput.click();
    },
    loadCSV(e) {
      this.file = e.target.files[0];
      this.editedItem.file = this.file;
      this.csv = {
        fileName: this.file.name,
        fileSize: this.formatBytes(this.file.size),
        rowCount: 0
      };
      if (window.FileReader) {
        let reader = new FileReader();
        reader.readAsText(this.file, "UTF-8");
        reader.onload = evt => {
          this.csv.rowCount = this.validateHeadersReturnRowCount(reader.result);
          this.loading = false;
        };
        reader.onerror = evt => {
          if (evt.target.error.name == "NotReadableError") {
            this.loading = false;
            this.$framework.showDialog(
              "Error",
              "Unable to read file.",
              "error"
            );
          }
        };
      } else {
        this.loading = false;
        this.$framework.showDialog(
          "Error",
          "FileReader is not supported in this browser.",
          "error"
        );
      }
    },
    validateHeadersReturnRowCount(csv) {
      let lines = csv.split("\n");
      let fileHeaders = lines[0].split(",").map(header => header.trim());
      var missingHeaders = this.dataTypeCSVHeaders
        .filter(e => !fileHeaders.includes(e))
        .join("<br/>");
      var additionalHeaders = fileHeaders
        .filter(e => !this.dataTypeCSVHeaders.includes(e))
        .join("<br/>");
      var duplicateHeaders = fileHeaders.filter(
        (item, index) => fileHeaders.indexOf(item) != index
      );

      if (missingHeaders.length) {
        this.loading = false;
        this.$framework.showDialog(
          "Error",
          `There are missing headers in your file:<br/> ${missingHeaders}`,
          "error"
        );
        return null;
      } else if (additionalHeaders.length) {
        this.loading = false;
        this.$framework.showDialog(
          "Warning",
          `Additional headers exist in file. The following will not be uploaded:<br/> ${additionalHeaders}`,
          "warning"
        );
        return lines.length;
      } else if (duplicateHeaders.length) {
        this.loading = false;
        this.$framework.showDialog(
          "Warning",
          `Duplicate headers exist in file. You will need to remove one of the duplicate columns before uploading the file:<br/> ${duplicateHeaders}`,
          "warning"
        );
        return null;
      }
      return lines.length;
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return "0 Bytes";
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }
  },
  watch: {
    csv: {
      handler: function(newValue, oldValue) {
        if (newValue) {
          this.editedItem.uploadedData = newValue.data;
        }
      },
      immediate: true
    }
  }
};
</script>
<style>
.wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 12px;
  margin-top: 4px;
  margin: 4px 0 0;
}
</style>
