import Vue from "vue";
import VueRouter from "vue-router";
import Dashboard from "../views/Dashboard";
import Upload from "../views/Upload";
import View from "../views/View";
import Users from "../views/Users";
import Login from "../views/Login";
import Reset from "../views/Reset";
import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    name: "Home Page",
    path: "/",
    component: Dashboard,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Login",
    path: "/login",
    component: Login
  },
  {
    name: "Password Reset",
    path: "/reset",
    component: Reset
  },
  {
    name: "Upload",
    path: "/upload",
    component: Upload,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "View",
    path: "/view",
    component: View,
    meta: {
      requiresAuth: true
    }
  },
  {
    name: "Users",
    path: "/users",
    component: Users,
    meta: {
      requiresAuth: true
    }
  }
];

const router = new VueRouter({
  routes,
  mode: "history",
  base: process.env.BASE_URL
});

router.beforeEach(async (to, from, next) => {
  var localStorageItem = JSON.parse(
    localStorage.getItem(`${location.hostname}.user`)
  );
  if (to.meta.requiresAuth && !localStorageItem) {
    next({
      name: "Login",
      params: { nextUrl: to.fullPath }
    });
  } else if (to.name != "Login" && to.name != "Password Reset") {
    if (localStorageItem.guid) {
      await axios
        .get(`api/user/get/guid/${localStorageItem.guid}`)
        .then(u => {
          let user = {};
          if (u.data) {
            user = u.data;
            if (user.id) {
              next();
            } else {
              next({
                name: "Login",
                params: { nextUrl: to.fullPath }
              });
            }
          } else {
            next({
              name: "Login",
              params: { nextUrl: to.fullPath }
            });
          }
        })
        .catch(error => {});
    } else {
      next({
        name: "Login",
        params: { nextUrl: to.fullPath }
      });
    }
  } else {
    next();
  }
});

const DEFAULT_TITLE = "Microsoft Integration Dashboard";

router.afterEach((to, from) => {
  Vue.nextTick(() => {
    document.title = DEFAULT_TITLE;
  });
});

export default router;
