import storeExtensions from "@/plugins/store-extensions";

export const dataTypesModule = {
  namespaced: true,
  state: {
    axios: storeExtensions.createAxios(), // Needed for different baseUrl
    dataTypes: [],
    dataType: {}
  },
  getters: {
    dataTypes: state => state.dataTypes,
    dataType: state => state.dataType
  },
  mutations: {
    updateDataTypes(state, newDataTypes) {
      state.dataTypes = newDataTypes;
    },
    updateDataType(state, newDataType) {
      state.dataType = newDataType;
    }
  },
  actions: {
    ...storeExtensions.actions,
    getAllDataTypes: (context, payload) => {
      return context.dispatch("getApiData", {
        url: `api/view/dataTypes`,
        mutation: "updateDataTypes",
        label: `Data Types`
      });
    },
    getDataTypeById: (context, payload) =>
      context.dispatch("getApiData", {
        url: `api/view/dataTypes/id/${payload}`,
        mutation: "updateDataType",
        label: `Data Type`
      }),
    getDataTypeByName: (context, payload) =>
      context.dispatch("getApiData", {
        url: `api/view/dataTypes/name/${payload}`,
        mutation: "updateDataTypes",
        label: `Data Type`
      })
  }
};
