<template>
  <div style="height: 600px" class="py-10 mb-5">
    <template v-if="statusReady && seriesData">
      <ag-charts-vue class="pb-3" :options="scatterPlotOptions" />
    </template>
    <v-alert v-else :value="true" type="info">
      No Chart to display
    </v-alert>
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import ChartsMixin from "../../../mixins/charts-mixin";
import { AgChartsVue } from "ag-charts-vue";

export default {
  props: ["configChartOptions", "seriesData"],
  mixins: [ChartsMixin],
  components: {
    AgChartsVue
  },
  data: () => ({
    statusReady: false
  }),
  mounted() {
    this.statusReady = true;
  },
  watch: {
    seriesData: {
      handler(newVal) {
        if (newVal) {
          this.setScatterPlotOptions(this.configChartOptions, newVal);
        }
      },
      deep: true,
      immediate: true
    }
  }
};
</script>

<style lang="scss"></style>
