export default {
  methods: {
    blurDropdown(dropdownRef) {
      //Vuetify select not closing on blur, use this workaround for now
      const itemDropdown = this.$refs[dropdownRef];

      if (this.$refs[dropdownRef]) {
        if (itemDropdown.isMenuActive) {
          this.$refs[dropdownRef].isMenuActive = false;
          itemDropdown.blur();
        }
      }
    }
  }
};
