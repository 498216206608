<template>
  <div style="height: 600px" class="py-10 mb-5">
    <template v-if="statusReady && seriesData">
      <ag-charts-vue
        class="pb-3"
        :options="barChartOptions"
        v-if="statusReady"
      />
    </template>
    <v-alert v-else :value="true" type="info">
      No Bar Chart to display
    </v-alert>
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { AgChartsVue } from "ag-charts-vue";
export default {
  name: "Log Timestamp Breakdown Chart",
  props: ["seriesData"],
  components: {
    AgChartsVue
  },
  data: () => ({
    chartStyle: {
      fontColor: {
        dark: "#eee",
        light: "#444"
      }
    },
    statusReady: false
  }),
  computed: {
    chartFontStyle() {
      return {
        color: this.chartStyle.fontColor[
          this.$vuetify.theme.dark ? "dark" : "light"
        ],
        fontWeight: 300,
        fontFamily: "Roboto, sans-serif"
      };
    },
    barChartOptions() {
      return {
        autoSize: true,
        legend: {
          enabled: false
        },
        title: {
          text: "Log Timestamp Breakdown",
          fontSize: 20,
          color: this.chartStyle.fontColor[
            this.$vuetify.theme.dark ? "dark" : "light"
          ]
        },
        data: this.seriesData,
        background: {
          visible: false
        },
        series: this.chartSeries,
        axes: [
          {
            type: "category",
            position: "bottom",
            title: {
              enabled: true,
              text: "Timestamp",
              color: this.chartStyle.fontColor[
                this.$vuetify.theme.dark ? "dark" : "light"
              ]
            },
            label: { ...this.chartFontStyle, rotation: -90 }
          },
          {
            type: "number",
            position: "left",
            tick: {
              count: 5
            },
            title: {
              enabled: true,
              text: "Number of Logs",
              color: this.chartStyle.fontColor[
                this.$vuetify.theme.dark ? "dark" : "light"
              ]
            },
            label: { ...this.chartFontStyle }
          }
        ]
      };
    },
    chartSeries() {
      return [
        {
          type: "column",
          xKey: "timestamp",
          yKeys: ["count"],
          tooltip: {
            renderer: params => {
              return {
                content: `<div><b>${params.xValue}</b>: ${params.yValue}</div>`
              };
            }
          }
        }
      ];
    }
  },
  mounted() {
    this.statusReady = true;
  },
  watch: {
    seriesData() {
      this.statusReady = false;
      this.$nextTick(() => {
        this.statusReady = true;
      });
    }
  }
};
</script>

<style lang="scss">
.toolPanel {
  text-align: center;
  font-size: 12px;
  font-family: Verdana, Arial, Helvetica, sans-serif;
}
.stacked-checkbox .v-input__slot {
  text-align: center;
  display: block;
}
.column-chart-selector {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
