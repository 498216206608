<template>
  <div>
    <v-overlay opacity="0.25" :value="loading" style="z-index:20001;">
      <v-progress-circular
        indeterminate
        color="#2A3F54"
        size="75"
      ></v-progress-circular>
    </v-overlay>
    <page-filters
      @filter-stickied="filterStickied = $event"
      @filter-expanded="filterExpanded = $event"
      initial-height="70px"
      small-viewport-height="225px"
      disable-expansion="true"
    >
      <div slot="filterItems" class="filter-items-content">
        <v-row class="mx-5">
          <v-col cols="12" :md="mediumBreakpointColumnSize" lg="4">
            <v-select
              v-model="product.selected"
              :items="products"
              label="Select Product"
              dense
            ></v-select>
          </v-col>
          <v-col cols="12" :md="mediumBreakpointColumnSize" lg="4">
            <date-selector
              :format="format"
              data-type="dppm"
              :disabled="filterStickied && !filterExpanded"
              @date-range-changed="setDateRange"
            />
          </v-col>
          <v-col
            cols="12"
            :md="mediumBreakpointColumnSizeActions"
            lg="4"
            class="d-flex"
          >
            <v-btn @click="clearFilters">Clear Filters</v-btn>
            <v-btn class="success ml-3" @click="fetchData">Apply Filters</v-btn>
          </v-col>
        </v-row>
      </div>
    </page-filters>
    <v-row class="ma-5">
      <v-col class="d-flex justify-end ma-0">
        <p class="font-italic">Last Updated: {{ lastModifiedDate }}</p>
      </v-col>
      <v-col cols="12">
        <div>
          <v-skeleton-loader :loading="rowDataLoading" type="image">
            <dppm-chart :format="format" :row-data="rowDataFiltered" />
          </v-skeleton-loader>
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-5">
      <v-col cols="12">
        <v-skeleton-loader
          :loading="rowDataLoading || !rowDataFiltered"
          type="table"
        >
          <dppm-grid :format="format" :row-data="rowDataFiltered" />
        </v-skeleton-loader>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DPPMChart from "../DPPM/DPPMChart.vue";
import DPPMGrid from "../DPPM/DPPMGrid.vue";
import PageFilters from "../PageFilters.vue";
import DateSelector from "../DateSelector.vue";

export default {
  name: "DPPM",
  props: ["format"],
  mixins: [],
  components: {
    "dppm-chart": DPPMChart,
    "dppm-grid": DPPMGrid,
    PageFilters,
    DateSelector
  },
  data: () => ({
    loading: false,
    selectedDateRange: {
      name: "Last 365 Days",
      dates: [
        dayjs()
          .startOf("day")
          .subtract(1, "years"),
        dayjs()
      ]
    },
    product: {
      selected: "View All",
      applied: "View All"
    },
    filterStickied: false,
    filterExpanded: false
  }),
  created() {},
  computed: {
    mediumBreakpointColumnSize() {
      return this.$vuetify.breakpoint.width < 1145 ? 3 : 4;
    },
    mediumBreakpointColumnSizeActions() {
      return this.$vuetify.breakpoint.width < 1145 ? 6 : 4;
    },
    displayFilterActions() {
      return !this.filterStickied || this.filterExpanded;
    },
    dataType() {
      return this.$store.getters["dataTypesModule/dataTypes"].find(
        x => x.name == "DPPM Data"
      );
    },
    dataRowGroups() {
      return this.$store.getters["dppmModule/files"].filter(
        x => x.format == this.format && x.dataTypeId === this.dataType.id
      );
    },
    rowData() {
      let data = [];
      this.dataRowGroups.forEach((dataGroup, index) => {
        let dataDate = dataGroup.dataDate;
        let dataGroupData = dataGroup.data || [];
        let rows = dataGroupData.map(row => {
          var vendorAndLocation = `${row["vendor"]}-${row["location"]}`;
          return {
            ...row,
            dataDate: dataDate,
            vendorLocationAgg: vendorAndLocation
          };
        });
        data = [...data, ...rows];
      });
      return data;
    },
    rowDataFiltered() {
      return this.product.applied && this.product.applied != "View All"
        ? this.rowData.filter(x => x.productName == this.product.applied)
        : this.rowData;
    },
    products() {
      return [
        ...new Set(this.rowData.map(row => row.productName)),
        "View All"
      ].sort();
    },
    lastModifiedDate() {
      let dates = this.$store.getters["dppmModule/previewFiles"]
        .filter(x => x.format == this.format)
        .map(file => dayjs(file.createdDate));
      return dates.length
        ? dates.reduce((a, b) => (a > b ? a : b)).format("MM/DD/YYYY")
        : "";
    }
  },
  methods: {
    setDateRange(dates) {
      this.selectedDateRange = dates;
    },
    async fetchData() {
      this.product.applied = this.product.selected;
      this.loading = true;
      await this.$store
        .dispatch("dppmModule/getFilteredFiles", {
          startDate: this.selectedDateRange.dates[0].format("YYYY-MM-DD"),
          endDate: this.selectedDateRange.dates[1].format("YYYY-MM-DD")
        })
        .catch(error => {
          this.$framework.showDialog(
            "",
            `<div class="error--text">${error}</div>`,
            "error"
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clearFilters() {
      this.product.selected = "View All";
    }
  },
  beforeMount() {
    this.$store.dispatch("dppmModule/getPreviewFiles");
  }
};
</script>

<style lang="scss">
.dppm-content .v-window__container {
  height: 100% !important;
}
.dppm-content .v-window-item {
  height: 100% !important;
}
</style>
