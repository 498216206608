<template>
  <div style="height: 800px" class="pb-10 mb-5">
    <ag-charts-vue
      :options="pieChartOptions"
      v-if="statusReady && data.length > 0"
    />
    <v-alert v-else :value="true" type="info">
      No Pie Chart to display
    </v-alert>
  </div>
</template>

<script>
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "ag-grid-community/dist/styles/ag-theme-alpine-dark.css";
import { AgChartsVue } from "ag-charts-vue";
export default {
  name: "Line Returns Chart",
  props: ["format", "product", "title", "dataKey", "rowData"],
  components: {
    AgChartsVue
  },
  data: () => ({
    chartStyle: {
      fontColor: {
        dark: "#eee",
        light: "#444"
      }
    },
    statusReady: false
  }),
  computed: {
    smallScreenSize() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    chartFontStyle() {
      return {
        color: this.chartStyle.fontColor[
          this.$vuetify.theme.dark ? "dark" : "light"
        ],
        fontWeight: 300,
        fontFamily: "Roboto, sans-serif"
      };
    },
    pieChartOptions() {
      return {
        padding: { top: 50, bottom: 50 },
        title: {
          text: this.title,
          fontSize: 20,
          color: this.chartStyle.fontColor[
            this.$vuetify.theme.dark ? "dark" : "light"
          ]
        },
        background: {
          visible: false
        },
        legend: {
          enabled: true,
          position: this.smallScreenSize ? "bottom" : "right",
          item: {
            label: {
              color: this.chartStyle.fontColor[
                this.$vuetify.theme.dark ? "dark" : "light"
              ]
            }
          }
        },
        series: [
          {
            data: this.data,
            type: "pie",
            labelKey: "label",
            angleKey: "value",
            label: { minAngle: 10, ...this.chartFontStyle },
            callout: { strokeWidth: 1, length: 15 }
          }
        ]
      };
    },
    failureTypes() {
      return [...new Set(this.rowData.map(row => row[this.dataKey]))];
    },
    data() {
      var failureCounts = [];
      this.failureTypes.forEach((failureType, index) => {
        var failureCount = this.rowData.reduce(
          (n, x) => n + (x[this.dataKey] === failureType),
          0
        );
        failureCounts.push({
          label: failureType,
          value: failureCount
        });
      });
      return failureCounts;
    }
  },
  async mounted() {
    this.statusReady = true;
  },
  async beforeMount() {},

  methods: {},
  watch: {}
};
</script>

<style lang="scss"></style>
