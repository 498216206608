<template>
  <div>
    <v-overlay opacity="0.25" :value="loading" style="z-index:20001;">
      <v-progress-circular
        indeterminate
        color="#2A3F54"
        size="75"
      ></v-progress-circular>
    </v-overlay>

    <page-filters
      @filter-stickied="filterStickied = $event"
      @filter-expanded="filterExpanded = $event"
    >
      <div slot="filterItems" class="filter-items-content">
        <v-row class="mx-5">
          <v-col cols="12" :md="filterItemWidth">
            <v-autocomplete
              :disabled="filterStickied && !filterExpanded"
              allow-overflow="false"
              label="Selected Models"
              dense
              search
              v-model="selectedModels"
              :items="models"
              @blur.prevent="blurDropdown('models')"
              ref="models"
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :close="editable"
                  :disabled="!editable"
                  @click="data.select"
                  @click:close="removeModel(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" :md="filterItemWidth">
            <v-autocomplete
              :disabled="filterStickied && !filterExpanded"
              allow-overflow="false"
              label="Selected Clusters"
              dense
              search
              v-model="selectedClusters"
              :items="clusters"
              @blur.prevent="blurDropdown('clusters')"
              ref="clusters"
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :close="editable"
                  :disabled="!editable"
                  @click="data.select"
                  @click:close="removeCluster(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" :md="filterItemWidth">
            <v-autocomplete
              :disabled="filterStickied && !filterExpanded"
              label="Selected Nodes"
              dense
              search
              v-model="selectedNodes"
              :items="nodes"
              @blur.prevent="blurDropdown('nodes')"
              ref="nodes"
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :close="editable"
                  :disabled="!editable"
                  @click="data.select"
                  @click:close="removeNode(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" :md="filterItemWidth">
            <v-autocomplete
              :disabled="filterStickied && !filterExpanded"
              label="Selected SKU's"
              dense
              search
              v-model="selectedSKUs"
              :items="skus"
              @blur.prevent="blurDropdown('skus')"
              ref="skus"
              multiple
              clearable
            >
              <template v-slot:selection="data">
                <v-chip
                  small
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  :close="editable"
                  :disabled="!editable"
                  @click="data.select"
                  @click:close="removeSKU(data.item)"
                >
                  {{ data.item }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" :md="filterItemWidth">
            <date-selector
              data-type="exSmart"
              :format="format"
              :disabled="filterStickied && !filterExpanded"
              @date-range-changed="setDateRange"
            />
          </v-col>
          <v-col v-if="displayFilterActions" cols="12" md="4" class="d-flex">
            <v-btn @click="clearFilters">Clear Filters</v-btn>
            <v-btn class="success ml-3" @click="loadData">Apply Filters</v-btn>
          </v-col>
        </v-row>
      </div>
    </page-filters>

    <v-row class="ma-5">
      <v-col class="d-flex justify-end ma-0">
        <p class="font-italic">Last Updated: {{ lastModifiedDate }}</p>
      </v-col>
      <v-col cols="12">
        <div>
          <model-overview-chart :series-data="chartData.modelOverview" />
        </div>
        <div>
          <log-timestamp-breakdown-chart
            :series-data="chartData.logTimeStamp"
          />
        </div>
        <div>
          <scatter-plot
            :config-chart-options="CHART_CONFIG.PowerOnHours"
            :series-data="chartData.pohDistribution"
          />
        </div>
        <div>
          <scatter-plot
            :config-chart-options="CHART_CONFIG.Workload"
            :series-data="chartData.workloadDistribution"
          />
        </div>
        <div>
          <scatter-plot
            :config-chart-options="CHART_CONFIG.Temperature"
            :series-data="chartData.temperatureDistribution"
          />
        </div>
        <div>
          <scatter-plot
            :config-chart-options="CHART_CONFIG.ErrorCount"
            :series-data="chartData.errorCountDistribution"
          />
        </div>
        <div>
          <scatter-plot
            :config-chart-options="CHART_CONFIG.ErrorCountNormalized"
            :series-data="chartData.errorCountNormalizedDistribution"
          />
        </div>
        <div>
          <scatter-plot
            :config-chart-options="CHART_CONFIG.ElectronicsErrorCount"
            :series-data="chartData.electronicsErrorCountDistribution"
          />
        </div>
        <div>
          <scatter-plot
            :config-chart-options="CHART_CONFIG.ShockSensor"
            :series-data="chartData.shockSensorEventDistribution"
          />
        </div>
        <div>
          <scatter-plot
            :config-chart-options="CHART_CONFIG.ShockSensorNormalized"
            :series-data="chartData.shockSensorEventNormalizedDistribution"
          />
        </div>
        <div>
          <scatter-plot
            :config-chart-options="CHART_CONFIG.ServoEvent"
            :series-data="chartData.servoEventDistribution"
          />
        </div>
        <div>
          <scatter-plot
            :config-chart-options="CHART_CONFIG.ReassignEvent"
            :series-data="chartData.reassignEventDistribution"
          />
        </div>
      </v-col>
    </v-row>
    <v-row class="ma-5">
      <v-col cols="12">
        <ex-smart-grid :format="format" :page-filters="appliedFilters" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dayjs from "dayjs";
import PageFilters from "../PageFilters.vue";
import ExSmartGrid from "../ExSmart/ExSmartGrid.vue";
import ModelOverviewChart from "./Charts/ModelOverview.vue";
import LogTimestampBreakdownChart from "./Charts/LogTimestampBreakdown.vue";
import ScatterPlot from "./Charts/ScatterPlot.vue";
import { ChartConfiguration } from "../../utils/chartConfiguration.js";
import DropdownActionsMixin from "../../mixins/dropdown-actions-mixin";
import DateSelector from "../DateSelector.vue";

export default {
  name: "ExSmart",
  props: ["format"],
  mixins: [DropdownActionsMixin],
  components: {
    DateSelector,
    PageFilters,
    ExSmartGrid,
    ModelOverviewChart,
    LogTimestampBreakdownChart,
    ScatterPlot
  },
  data: () => ({
    loading: true,
    maxMonthYear: dayjs().format("YYYY-MM"),
    selectedDateRange: {
      name: "Last 365 Days",
      dates: [
        dayjs()
          .startOf("day")
          .subtract(1, "years"),
        dayjs()
      ]
    },
    userFieldSettings: [],
    selectedClusters: [],
    selectedNodes: [],
    selectedSKUs: [],
    selectedModels: [],
    appliedFilters: {},
    filterStickied: false,
    filterExpanded: false
  }),
  created() {
    this.CHART_CONFIG = ChartConfiguration;
  },
  computed: {
    smallScreenSize() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    filterItemWidth() {
      return this.filterStickied ? (this.filterExpanded ? 4 : 2) : 4;
    },
    displayFilterActions() {
      return !this.filterStickied || this.filterExpanded;
    },
    loggedInUser() {
      return this.$store.getters["userModule/user"];
    },
    dataType() {
      return this.$store.getters["dataTypesModule/dataTypes"].find(
        x => x.name == "exSmart"
      );
    },
    dataTypeSettings() {
      return this.$store.getters["userSettingsModule/selectedFields"].find(
        item => item.itemId == this.dataType.id
      )?.data;
    },
    clusters() {
      return this.$store.getters["exSmartModule/clusters"]
        .filter(x => {
          return x.format == this.format && x.value != null && x.value != "";
        })
        .map(x => x.value);
    },
    nodes() {
      return this.$store.getters["exSmartModule/nodes"]
        .filter(x => {
          return x.format == this.format && x.value != null && x.value != "";
        })
        .map(x => x.value);
    },
    skus() {
      return this.$store.getters["exSmartModule/skus"]
        .filter(x => {
          return x.format == this.format && x.value != null && x.value != "";
        })
        .map(x => x.value);
    },
    models() {
      return this.$store.getters["exSmartModule/models"]
        .filter(x => {
          return x.format == this.format && x.value != null && x.value != "";
        })
        .map(x => x.value);
    },
    chartData() {
      return this.$store.getters["exSmartModule/chartData"];
    },
    filters() {
      return {
        format: this.format,
        startDate: this.selectedDateRange.dates[0].format("YYYY-MM-DD"),
        endDate: this.selectedDateRange.dates[1].format("YYYY-MM-DD"),
        fieldValuesFilter: {
          Cluster: this.selectedClusters,
          Nodeid: this.selectedNodes,
          DriveserialNumber: this.selectedSKUs,
          Model: this.selectedModels
        }
      };
    },
    lastModifiedDate() {
      let dates = this.$store.getters["exSmartModule/previewFiles"]
        .filter(x => x.format == this.format)
        .map(file => dayjs(file.createdDate));
      return dates.length
        ? dates.reduce((a, b) => (a > b ? a : b)).format("MM/DD/YYYY")
        : "";
    }
  },
  methods: {
    setDateRange(dates) {
      this.selectedDateRange = dates;
    },
    clearFilters() {
      this.selectedClusters = "";
      this.selectedNodes = "";
      this.selectedSKUs = "";
    },
    loadData() {
      this.loading = true;
      this.appliedFilters = this.filters;
      this.$store.dispatch("exSmartModule/resetChartData");
      Promise.all([
        this.$store.dispatch("exSmartModule/getModelOverview", this.filters),
        this.$store.dispatch("exSmartModule/getLogTimeStamp", this.filters),
        this.$store.dispatch("exSmartModule/getPOHDistribution", this.filters),
        this.$store.dispatch(
          "exSmartModule/getWorkloadDistribution",
          this.filters
        ),
        this.$store.dispatch(
          "exSmartModule/getTemperatureDistribution",
          this.filters
        ),
        this.$store.dispatch(
          "exSmartModule/getErrorCountDistribution",
          this.filters
        ),
        this.$store.dispatch(
          "exSmartModule/getErrorCountNormalizedDistribution",
          this.filters
        ),
        this.$store.dispatch(
          "exSmartModule/getElectronicsErrorCountDistribution",
          this.filters
        ),
        this.$store.dispatch(
          "exSmartModule/getShockSensorEventDistribution",
          this.filters
        ),
        this.$store.dispatch(
          "exSmartModule/getShockSensorEventNormalizedDistribution",
          this.filters
        ),
        this.$store.dispatch(
          "exSmartModule/getServoEventDistribution",
          this.filters
        ),
        this.$store.dispatch(
          "exSmartModule/getReassignEventDistribution",
          this.filters
        )
      ]).then(x => (this.loading = false));
    },
    removeCluster(item) {
      const index = this.selectedClusters.findIndex(x => x == item);
      if (index >= 0) {
        this.selectedClusters.splice(index, 1);
      }
    },
    removeNode(item) {
      const index = this.selectedNodes.findIndex(x => x == item);
      if (index >= 0) {
        this.selectedNodes.splice(index, 1);
      }
    },
    removeSKU(item) {
      const index = this.selectedSKUs.findIndex(x => x == item);
      if (index >= 0) {
        this.selectedSKUs.splice(index, 1);
      }
    },
    removeModel(item) {
      const index = this.selectedModels.findIndex(x => x == item);
      if (index >= 0) {
        this.selectedModels.splice(index, 1);
      }
    }
  },
  async beforeMount() {
    this.$store.dispatch("exSmartModule/getDistinctClusters");
    this.$store.dispatch("exSmartModule/getDistinctNodes");
    this.$store.dispatch("exSmartModule/getDistinctSKUs");
    this.$store.dispatch("exSmartModule/getDistinctModels");
    this.$store.dispatch("exSmartModule/getPreviewFiles");
    this.loadData();
  }
};
</script>
