<template>
  <div class="main-content">
    <v-overlay opacity="0.25" :value="loading" style="z-index:20001;">
      <v-progress-circular
        indeterminate
        color="#2A3F54"
        size="75"
      ></v-progress-circular>
    </v-overlay>
    <v-tabs fixed-tabs icons-and-text>
      <v-tabs-slider></v-tabs-slider>
      <v-tab>
        HDD exSmart
        <v-icon>wdi-hdd-alternative</v-icon>
      </v-tab>
      <v-tab>
        HDD DPPM
        <v-icon>fas fa-location-arrow</v-icon>
      </v-tab>
      <v-tab>
        HDD Line Returns
        <v-icon>wdi-hdd-alternative</v-icon>
      </v-tab>
      <v-tab>
        SSD DPPM
        <v-icon>fas fa-location-arrow</v-icon>
      </v-tab>
      <v-tab-item>
        <v-divider></v-divider>
        <exsmart v-if="!loading" format="HDD" />
      </v-tab-item>
      <v-tab-item>
        <v-divider></v-divider>
        <dppm v-if="!loading" format="HDD" />
      </v-tab-item>
      <v-tab-item>
        <v-divider></v-divider>
        <line-returns v-if="!loading" format="HDD" />
      </v-tab-item>
      <v-tab-item>
        <v-divider></v-divider>
        <dppm v-if="!loading" format="SSD" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import dayjs from "dayjs";
import LineReturns from "../components/LineReturns/LineReturns.vue";
import DPPM from "../components/DPPM/DPPM.vue";
import ExSmart from "../components/ExSmart/ExSmart.vue";

export default {
  name: "Details",
  mixins: [],
  components: {
    "line-returns": LineReturns,
    dppm: DPPM,
    exsmart: ExSmart
  },
  data: () => ({
    dates: [
      dayjs()
        .startOf("day")
        .subtract(1, "years"),
      dayjs()
    ],
    loading: true,
    userFieldSettingsExSmart: []
  }),
  async created() {
    this.loading = true;
    await this.$store.dispatch("dataTypesModule/getAllDataTypes");
    await this.$store.dispatch(
      "userSettingsModule/getAllUserSelectedFields",
      this.loggedInUser.id
    );
    this.setUserFieldSettingsExSmart();
    Promise.all([
      this.$store.dispatch("dppmModule/getFilteredFiles", {
        startDate: this.dates[0].format("YYYY-MM-DD"),
        endDate: this.dates[1].format("YYYY-MM-DD")
      }),
      this.$store.dispatch("lineIntegrationModule/getFilteredFiles", {
        startDate: this.dates[0].format("YYYY-MM-DD"),
        endDate: this.dates[1].format("YYYY-MM-DD")
      }),
      this.$store.dispatch("exSmartModule/getDBFields")
    ])
      .catch(error => {
        this.$framework.showDialog(
          "",
          `<div class="error--text">${error}</div>`,
          "error"
        );
      })
      .finally(() => {
        this.loading = false;
      });
  },
  computed: {
    loggedInUser() {
      return this.$store.getters["userModule/user"];
    },
    userSelectedFields() {
      return this.$store.getters["userSettingsModule/selectedFields"];
    },
    dataTypes() {
      return this.$store.getters["dataTypesModule/dataTypes"];
    },
    exSmartDataType() {
      return this.dataTypes.find(x => x.name == "exSmart");
    }
  },
  methods: {
    setUserFieldSettingsExSmart() {
      let data =
        this.userSelectedFields.find(
          item => item.itemId == this.exSmartDataType.id
        )?.data ||
        this.exSmartDataType.schema
          .filter(x => !x.gridSettings.hide)
          .map(x => x.cleanName);
      this.userFieldSettingsExSmart = JSON.parse(JSON.stringify(data));
    }
  }
};
</script>

<style lang="scss" scoped>
.main-content .v-window__container {
  height: auto !important;
}
.main-content .v-window-item {
  height: auto !important;
}
</style>
