import storeExtensions from "@/plugins/store-extensions";

export const adminModule = {
  namespaced: true,
  state: {
    axios: storeExtensions.createAxios(), // Needed for different baseUrl
    files: []
  },
  getters: {
    files: state => state.files
  },
  mutations: {
    updateFiles(state, newFiles) {
      state.files = newFiles;
    }
  },
  actions: {
    ...storeExtensions.actions,
    addFile: (context, payload) => {
      return context.dispatch("postApiData", {
        url: `api/edit`,
        payload: payload,
        headers: {
          "Content-Type": "application/octet-stream"
        },
        mutation: "updateFiles",
        label: `Insert New File into database`
      });
    },
    retireFile: (context, payload) => {
      return context.dispatch("putApiData", {
        url: `api/edit/retire`,
        payload: payload,
        label: `Retire File from database`
      });
    },
    validate: (context, payload) => {
      return context.dispatch("postApiData", {
        url: `api/edit/validate`,
        payload: payload,
        label: `Validate data doesnt already exist.`
      });
    },
    downloadFileData: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.rootState.axios
          .get(`api/view/download?id=${payload}`, { responseType: "blob" })
          .then(response => {
            if (response.status === 200) {
              resolve(response);
            } else {
              reject("There was an error downloading the data.");
            }
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    addUser: (context, payload) => {
      context.dispatch("postApiData", {
        url: `api/user/download?id=${payload}`,
        label: `Add User`
      });
    },
    updateUser: (context, payload) => {
      context.dispatch("postApiData", {
        url: `api/user/download?id=${payload}`,
        label: `Update User`
      });
    }
  }
};
