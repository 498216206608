import storeExtensions from "@/plugins/store-extensions";
const initialChartData = {
  modelOverview: [],
  logTimeStamp: [],
  pohDistribution: [],
  workloadDistribution: [],
  temperatureDistribution: [],
  errorCountDistribution: [],
  errorCountNormalizedDistribution: [],
  electronicsErrorCountDistribution: [],
  shockSensorEventDistribution: [],
  shockSensorEventNormalizedDistribution: [],
  servoEventDistribution: [],
  reassignEventDistribution: []
};
export const exSmartModule = {
  namespaced: true,
  state: {
    axios: storeExtensions.createAxios(), // Needed for different baseUrl
    file: {},
    rowData: [],
    previewFiles: [],
    dbFields: [],
    clusters: [],
    nodes: [],
    skus: [],
    models: [],
    gridData: [],
    chartData: {
      modelOverview: [],
      logTimeStamp: [],
      pohDistribution: [],
      workloadDistribution: [],
      temperatureDistribution: [],
      errorCountDistribution: [],
      errorCountNormalizedDistribution: [],
      electronicsErrorCountDistribution: [],
      shockSensorEventDistribution: [],
      shockSensorEventNormalizedDistribution: [],
      servoEventDistribution: [],
      reassignEventDistribution: []
    }
  },
  getters: {
    axios: state => state.axios,
    file: state => state.file,
    rowData: state => state.rowData,
    previewFiles: state => state.previewFiles,
    dbFields: state => state.dbFields,
    clusters: state => state.clusters,
    nodes: state => state.nodes,
    skus: state => state.skus,
    models: state => state.models,
    gridData: state => state.gridData,
    chartData: state => state.chartData
  },
  mutations: {
    updateFile(state, newFile) {
      state.file = newFile;
    },
    updateRowData(state, newFiles) {
      let rowData = [];
      newFiles.forEach((newFile, index) => {
        rowData = [...rowData, ...newFile.data];
      });
      state.rowData = rowData;
    },
    updatePreviewFiles(state, newFiles) {
      state.previewFiles = newFiles;
    },
    updateDbFields(state, newDbFields) {
      state.dbFields = newDbFields;
    },
    updateDistinctClusters(state, newClusters) {
      state.clusters = newClusters;
    },
    updateDistinctNodes(state, newNodes) {
      state.nodes = newNodes;
    },
    updateDistinctSKUs(state, newSKUs) {
      state.skus = newSKUs;
    },
    updateDistinctModels(state, newModels) {
      state.models = newModels;
    },
    updateGridData(state, newGridData) {
      state.gridData = newGridData;
    },
    updateModelOverview(state, newModelOverview) {
      state.chartData.modelOverview = newModelOverview;
    },
    updateLogTimeStamp(state, newLogTimeStamps) {
      state.chartData.logTimeStamp = newLogTimeStamps;
    },
    updatePOHDistribution(state, newPOHDistribution) {
      state.chartData.pohDistribution = newPOHDistribution;
    },
    updateWorkloadDistribution(state, newWorkloadDistribution) {
      state.chartData.workloadDistribution = newWorkloadDistribution;
    },
    updateTemperatureDistribution(state, newTemperatureDistribution) {
      state.chartData.temperatureDistribution = newTemperatureDistribution;
    },
    updateErrorCountDistribution(state, newErrorCountDistribution) {
      state.chartData.errorCountDistribution = newErrorCountDistribution;
    },
    updateErrorCountNormalizedDistribution(
      state,
      newErrorCountNormalizedDistribution
    ) {
      state.chartData.errorCountNormalizedDistribution = newErrorCountNormalizedDistribution;
    },
    updateElectronicsErrorCountDistribution(
      state,
      newElectronicsErrorCountDistribution
    ) {
      state.chartData.electronicsErrorCountDistribution = newElectronicsErrorCountDistribution;
    },
    updateShockSensorEventDistribution(state, newShockSensorEventDistribution) {
      state.chartData.shockSensorEventDistribution = newShockSensorEventDistribution;
    },
    updateShockSensorEventNormalizedDistribution(
      state,
      newShockSensorEventNormalizedDistribution
    ) {
      state.chartData.shockSensorEventNormalizedDistribution = newShockSensorEventNormalizedDistribution;
    },
    updateServoEventDistribution(state, newServoEventDistribution) {
      state.chartData.servoEventDistribution = newServoEventDistribution;
    },
    updateReassignEventDistribution(state, newReassignEventDistribution) {
      state.chartData.reassignEventDistribution = newReassignEventDistribution;
    },
    resetChartData(state) {
      state.chartData = JSON.parse(JSON.stringify(initialChartData));
    }
  },
  actions: {
    ...storeExtensions.actions,
    getPreviewFiles: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/view/files/search`,
        mutation: "updatePreviewFiles",
        payload: {
          ...payload,
          ...{ dataType: "exSmart", excludeJSON: true }
        },
        label: `Files`
      }),
    getFile: (context, payload) =>
      context.dispatch("getApiData", {
        url: `api/view/file/id/${payload}`,
        mutation: "updateFile",
        label: `File`
      }),
    getFilteredFiles: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/view/files/search`,
        mutation: "updateRowData",
        payload: { ...payload, ...{ dataType: "exSmart" } },
        label: `Files`
      }),
    getDBFields: (context, payload) =>
      context.dispatch("getApiData", {
        url: `api/view/dbFields/exSmart`,
        mutation: "updateDbFields",
        label: `DB Fields`
      }),
    getDistinctClusters: context =>
      context.dispatch("postApiData", {
        url: `api/view/data/search`,
        mutation: "updateDistinctClusters",
        payload: { dataType: "exSmart", field: "cluster" },
        label: `Distinct Clusters`
      }),
    getDistinctNodes: context =>
      context.dispatch("postApiData", {
        url: `api/view/data/search`,
        mutation: "updateDistinctNodes",
        payload: { dataType: "exSmart", field: "nodeid" },
        label: `Distinct Nodes`
      }),
    getDistinctSKUs: context =>
      context.dispatch("postApiData", {
        url: `api/view/data/search`,
        mutation: "updateDistinctSKUs",
        payload: { dataType: "exSmart", field: "Driveserialnumber" },
        label: `Distinct SKU's`
      }),
    getDistinctModels: context =>
      context.dispatch("postApiData", {
        url: `api/view/data/search`,
        mutation: "updateDistinctModels",
        payload: { dataType: "exSmart", field: "Model" },
        label: `Distinct Models`
      }),
    getAgGridData: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/aggrid/exSmart`,
        mutation: "updateGridData",
        payload: { ...payload },
        label: `Fetch All ExSmart Grid Data`
      }),
    resetChartData: context => context.commit("resetChartData"),
    getModelOverview: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/modelOverview`,
        mutation: "updateModelOverview",
        payload: payload,
        label: `Fetch Model Overview Chart Data`
      }),
    getLogTimeStamp: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/logTimestamp`,
        mutation: "updateLogTimeStamp",
        payload: payload,
        label: `Fetch Log TimeStamp Chart Data`
      }),
    getPOHDistribution: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/pohDistribution`,
        mutation: "updatePOHDistribution",
        payload: payload,
        label: `Fetch POH Distribution Chart Data`
      }),
    getWorkloadDistribution: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/workloadDistribution`,
        mutation: "updateWorkloadDistribution",
        payload: payload,
        label: `Fetch Workload Distribution Chart Data`
      }),
    getTemperatureDistribution: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/temperatureDistribution`,
        mutation: "updateTemperatureDistribution",
        payload: payload,
        label: `Fetch Temperature Distribution Chart Data`
      }),
    getErrorCountDistribution: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/errorCountDistribution`,
        mutation: "updateErrorCountDistribution",
        payload: payload,
        label: `Fetch Error Count Distribution Chart Data`
      }),
    getErrorCountNormalizedDistribution: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/errorCountNormalizedDistribution`,
        mutation: "updateErrorCountNormalizedDistribution",
        payload: payload,
        label: `Fetch Error Count Normalized Distribution Chart Data`
      }),
    getElectronicsErrorCountDistribution: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/electronicsErrorCountDistribution`,
        mutation: "updateElectronicsErrorCountDistribution",
        payload: payload,
        label: `Fetch Electronics Error Count Distribution Chart Data`
      }),
    getShockSensorEventDistribution: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/shockSensorEventDistribution`,
        mutation: "updateShockSensorEventDistribution",
        payload: payload,
        label: `Fetch Shock Sensor Event Distribution Chart Data`
      }),
    getShockSensorEventNormalizedDistribution: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/shockSensorEventNormalizedDistribution`,
        mutation: "updateShockSensorEventNormalizedDistribution",
        payload: payload,
        label: `Fetch Shock Sensor Event Normalized Distribution Chart Data`
      }),
    getServoEventDistribution: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/servoEventDistribution`,
        mutation: "updateServoEventDistribution",
        payload: payload,
        label: `Fetch Servo Event Distribution Chart Data`
      }),
    getReassignEventDistribution: (context, payload) =>
      context.dispatch("postApiData", {
        url: `api/charts/reassignEventDistribution`,
        mutation: "updateReassignEventDistribution",
        payload: payload,
        label: `Fetch Reassign Event Distribution Chart Data`
      })
  }
};
