import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import aggrid from "./plugins/ag-grid.js";
Vue.config.productionTip = false;
Vue.use(aggrid);
new Vue({
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount("#app");

Vue.config.devtools = process.env.NODE_ENV === "development";
