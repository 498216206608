export default {
  data: () => ({
    chartStyle: {
      fontColor: {
        dark: "#eee",
        light: "#444"
      }
    },
    baseOptions: {},
    overrideOptions: {},
    seriesData: []
  }),
  computed: {
    chartFontStyle() {
      return {
        color: this.chartStyle.fontColor[
          this.$vuetify.theme.dark ? "dark" : "light"
        ],
        fontWeight: 300,
        fontFamily: "Roboto, sans-serif"
      };
    },
    color() {
      return this.chartStyle.fontColor[
        this.$vuetify.theme.dark ? "dark" : "light"
      ];
    },
    scatterPlotOptions() {
      return this.baseOptions;
    }
  },
  methods: {
    setScatterPlotOptions(options, seriesData) {
      this.seriesData = seriesData;
      this.overrideOptions = options;
      this.populateSeriesTooltips();
      this.populateScatterPlotOptions();
    },
    populateSeriesTooltips() {
      this.seriesData.forEach((group, index) => {
        if (group?.tooltip?.enabled) {
          group.tooltip = this.tooltipRenderer();
        }
      });
    },
    tooltipRenderer() {
      if (this.overrideOptions.name == "Workload") {
        return {
          renderer: params => {
            let convertedXName = params.xValue < 1 ? "Gigabytes" : params.xName;
            let convertedXValue =
              params.xValue < 1 ? params.xValue * 1024 : params.xValue;
            return {
              content:
                `<div><b>${convertedXName}</b>: ${convertedXValue}</div>` +
                `<div><b>${params.yName}</b>: ${params.yValue}</div>`
            };
          }
        };
      }
      return {
        renderer: params => {
          return {
            content:
              `<div><b>${params.xName}</b>: ${params.xValue}</div>` +
              `<div><b>${params.yName}</b>: ${params.yValue}</div>`
          };
        }
      };
    },
    populateScatterPlotOptions() {
      this.baseOptions = {
        autoSize: true,
        legend: {
          enabled: true,
          position: "right",
          item: {
            label: {
              color: this.color
            }
          }
        },
        title: {
          text: this.overrideOptions?.title?.text ?? "Title",
          fontSize: 20,
          color: this.color
        },
        background: {
          visible: false
        },
        series: this.seriesData,
        axes: [
          {
            ...(this.overrideOptions?.axes?.[0]?.min && {
              min: this.overrideOptions?.axes[0]?.min
            }),
            max: this.overrideOptions?.axes?.[0]?.max ?? this.xAxisMax(),
            type: this.overrideOptions?.axes?.[0]?.type ?? "number",
            position: "bottom",
            tick: {
              ...(this.overrideOptions?.axes?.[0]?.tick?.count && {
                count: this.overrideOptions?.axes?.[0]?.tick?.count
              })
            },
            title: {
              enabled: true,
              text:
                this.overrideOptions?.axes?.[0]?.title?.text ?? "Error Counter",
              color: this.color
            },
            label: {
              ...this.chartFontStyle,
              rotation: this.overrideOptions?.axes?.[0]?.label?.rotation ?? 0,
              format: this.overrideOptions?.axes?.[0]?.label?.format ?? null,
              ...(!this.overrideOptions?.axes?.[0]?.label?.format && {
                formatter: params => params.value
              })
            }
          },
          {
            ...(this.overrideOptions?.axes?.[1]?.min && {
              min: this.overrideOptions?.axes?.[1]?.min
            }),
            ...(this.overrideOptions?.axes?.[1]?.max && {
              max: this.overrideOptions?.axes?.[1]?.max
            }),
            type: this.overrideOptions?.axes?.[1]?.type ?? "number",
            position: "left",
            tick: {
              count: this.overrideOptions?.axes?.[1]?.tick?.count ?? 5
            },
            title: {
              enabled: true,
              text:
                this.overrideOptions?.axes?.[1]?.title?.text ??
                "Fraction of Logs",
              color: this.color
            },
            label: {
              ...this.chartFontStyle,
              rotation:
                this.overrideOptions?.axes?.[1]?.label?.rotation ?? null,
              format: this.overrideOptions?.axes?.[1]?.label?.format ?? null,
              formatter: params => {
                return params.value;
              }
            }
          }
        ]
      };
    },
    xAxisMax() {
      return this.seriesData.length
        ? Math.max(
            ...[
              ...new Set(
                this.seriesData.map(x => x.data.map(y => y.xKey)).flat()
              )
            ],
            1
          )
        : 1;
    }
  },
  watch: {
    color: {
      handler(newVal) {
        if (newVal) {
          this.populateScatterPlotOptions();
        }
      }
    }
  }
};
