import storeExtensions from "@/plugins/store-extensions";

export const userModule = {
  namespaced: true,
  state: {
    axios: storeExtensions.createAxios(), // Needed for different baseUrl
    user: {},
    users: []
  },
  getters: {
    user: state => state.user,
    users: state => state.users
  },
  mutations: {
    updateUser(state, newUser) {
      state.user = newUser;
    },
    updateUsers(state, newUsers) {
      state.users = newUsers;
    }
  },
  actions: {
    ...storeExtensions.actions,
    getAllUsers: (context, payload) =>
      context.dispatch("getApiData", {
        url: `api/user/get-all`,
        mutation: "updateUsers",
        label: `Users`
      }),
    getUserByGuid: (context, payload) =>
      context.dispatch("getApiData", {
        url: `api/user/get/guid/${payload}`,
        label: `User`
      }),
    createUser: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.rootState.axios
          .post(`api/user/create`, payload)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    updateUser: (context, payload) => {
      context.dispatch("postApiData", {
        url: `api/user/update`,
        payload: payload,
        label: `Update User`
      });
    },
    loginUser: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.rootState.axios
          .post(`api/user/login`, payload)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    sendUserPasswordResetEmail: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.rootState.axios
          .post(`api/user/passwordresetrequest`, payload)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    },
    resetUserPassword: (context, payload) => {
      return new Promise((resolve, reject) => {
        context.rootState.axios
          .post(`api/user/reset`, payload)
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  }
};
