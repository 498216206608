<template>
  <div />
</template>

<script>
export default {
  computed: {},
  mounted: function() {
    this.$nextTick(() => {
      var drawerItemsKey = [
        "uls-ep-mid01.ad.shared",
        "midinternal.ad.shared",
        "midinternal.wdc.com"
      ].includes(location.hostname)
        ? "admin"
        : "user";
      this.$framework.model.drawer.items = this.$framework.model.drawer.roleItems[
        drawerItemsKey
      ];
    });
  }
};
</script>
